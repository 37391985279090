import { useEffect, useState } from "react";

import {
  featureServices,
  moduleServices,
  sectorServices,
} from "services";

const BLACK_COLOR = "#000000";

const FeaturesLogic = () => {
  const [refresh, setRefresh] = useState(false);

  const [openNotif, setOpenNotif] = useState(false);
  const [notifMessage, setNotifMessage] = useState("");
  const [notifColor, setNotifColor] = useState("info");

  const [featuresList, setFeaturesList] = useState(undefined);

  const [editModalOpened, setEditModalOpened] = useState(false);
  const [newFeaturesInfo, setNewFeaturesInfo] = useState(undefined);
  const [modalType, setModalType] = useState(false);
  const [formModulesList, setFormModulesList] = useState(undefined);
  const [formAttributesList, setFormAttributesList] = useState(undefined);
  const [formValid, setFormValid] = useState(false);

  const [featureDestroy, setFeatureDestroy] = useState(undefined);

  const toggleConfirmFeatureDestroy = feature => setFeatureDestroy(feature);

  useEffect(() => {
    (async () => {
      const feature = await featureServices.getAll();
      if (feature) {
        setFeaturesList(feature);
      }
    })();
  }, [refresh]);

  const refreshData = () => setRefresh(!refresh);

  const toggleEnableDisableFeature = async feature => {
    if (feature.disabled) {
      await featureServices.enable(feature?.uuid);
    } else {
      await featureServices.disable(feature?.uuid);
    }
    refreshData();
  };

  const toggleEnableFeature = async feature => {
    await featureServices.enable(feature?.uuid);
    refreshData();
  };

  const openNotification = (msg, color = "info", time = 6) => {
    setOpenNotif(false);
    setNotifMessage(msg);
    setNotifColor(color);
    setTimeout(() => setOpenNotif(false), time * 1000);
    setTimeout(() => setOpenNotif(true), 100);
  };

  const updatePosition = async (uuid, position) => {
    return await featureServices.updatePosition(uuid, position);
  };

  const deleteFeature = async uuid => {
    //After Module/Feature Removing Confirmation
    await featureServices.destroy(uuid);
    const feature = featuresList.find(it => it.uuid === uuid);
    openNotification(
      `Le module ${feature?.custom_title} a été supprimé`,
      "success"
    );
    toggleConfirmFeatureDestroy(null);
    refreshData();
  };

  const getAllRessourceform = async () => {
    const module = await moduleServices.getAll();
    if (module) setFormModulesList(module);
    const attribute = await sectorServices.getAll();
    if (attribute) {
      setFormAttributesList(attribute);
    }
  };

  const toggleModal = async (feature = null) => {
    getAllRessourceform();
    setFormValid(false);
    setModalType(feature ? true : false);
    const featureInfo = {
      uuid: feature?.uuid,
      custom_title: feature?.custom_title ? feature?.custom_title : "",
      internal_title: feature?.internal_title ? feature?.internal_title : "",
      color: feature?.color ? feature?.color : BLACK_COLOR,
      module: feature?.module?.identifier ? feature?.module?.identifier : "",
      attributes: feature?.attributes
        ? feature?.attributes.map(it => it.uuid)
        : [],
    };
    setNewFeaturesInfo(featureInfo);
    setEditModalOpened(!editModalOpened);
  };

  const isFormValid = featureInfo => {
    if (
      !featureInfo?.custom_title?.length ||
      !featureInfo?.internal_title?.length ||
      !featureInfo?.color?.length ||
      !featureInfo?.module?.length
    ) {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  };

  const handleFormChange = (key, value) => {
    let featureInfo = { ...newFeaturesInfo };
    featureInfo[key] = value;
    setNewFeaturesInfo(featureInfo);
    isFormValid(featureInfo);
  };

  const submitNewFeature = async event => {
    event.preventDefault();
    setFormValid(false);
    const color =
      newFeaturesInfo.color[0] === "#"
        ? newFeaturesInfo.color.slice(1)
        : newFeaturesInfo.color;
    const featureInfo = {
      module_identifier: newFeaturesInfo.module,
      custom_title: newFeaturesInfo.custom_title,
      internal_title: newFeaturesInfo.internal_title,
      color,
    };
    if (newFeaturesInfo.uuid) {
      const feature = featuresList.find(it => it.uuid === newFeaturesInfo.uuid);
      const attributes = feature.attributes;
      attributes
        .filter(
          attribute =>
            !newFeaturesInfo.attributes.find(it => it === attribute.uuid)
        )
        .forEach(
          async it =>
            await featureServices.detachAttribute(newFeaturesInfo.uuid, it.uuid)
        );
      newFeaturesInfo.attributes
        .filter(it => !attributes.find(attribute => attribute.uuid === it))
        .forEach(
          async it =>
            await featureServices.attachAttribute(newFeaturesInfo.uuid, it)
        );
      if (await featureServices.update(newFeaturesInfo.uuid, featureInfo)) {
        openNotification(
          `Le module ${newFeaturesInfo?.custom_title} a été modifié`,
          "success"
        );
      } else {
        openNotification(
          `Erreur, une erreur est survenu lors de la modification du module ${newFeaturesInfo?.custom_title}`,
          "danger"
        );
      }
    } else {
      const feature = await featureServices.add(featureInfo);
      if (feature) {
        for (let x of newFeaturesInfo.attributes) {
          await featureServices.attachAttribute(feature.uuid, x);
        }
        openNotification(
          `Le module ${newFeaturesInfo?.custom_title} a été ajouté`,
          "success"
        );
      } else {
        openNotification(
          `Erreur, une erreur est survenu lors de l'ajout du module ${newFeaturesInfo?.custom_title}`,
          "danger"
        );
      }
    }
    setEditModalOpened(!editModalOpened);
    setFormValid(true);
    refreshData();
  };

  return {
    editModalOpened,
    featureDestroy,
    featuresList,
    formAttributesList,
    formModulesList,
    formValid,
    modalType,
    newFeaturesInfo,
    notifColor,
    notifMessage,
    openNotif,
    deleteFeature,
    handleFormChange,
    refreshData,
    setOpenNotif,
    updatePosition,
    submitNewFeature,
    toggleModal,
    toggleConfirmFeatureDestroy,
    toggleEnableDisableFeature,
  };
};

export default FeaturesLogic;
