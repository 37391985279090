import React from "react";

import styles from "assets/jss/material-dashboard-react/views/newsStyle.js";

// core components
import Tabs from "components/CustomTabs/CustomTabs.js";
import GridContainer from "components/Grid/GridContainer.js";
import AssignmentIcon from "@material-ui/icons/Assignment";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import BuildingListView from './TabViews/BuildingListView';
import BuildingImportView from './TabViews/BuildingImportView';

export default function Attributes(props) {

  return (
    <div>
      <GridContainer>
        <Tabs
          tabs={[
            {
              tabName: "Bâtiments",
              tabIcon: AssignmentIcon,
              tabContent: <BuildingListView />
            },
            {
              tabName: "Importer des bâtiments",
              tabIcon: GroupAddIcon,
              tabContent: <BuildingImportView />
            }
          ]}
        />
      </GridContainer>
    </div>
  )
}