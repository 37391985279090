import { ressourceConstants } from "../constants";
import { alertActions } from "../actions";
import { reservationRessourceServices } from "services";

export const ressourceActions = {
  getAll,
};

function getAll() {
  return dispatch => {
    dispatch(request());
    return reservationRessourceServices.getAllRessources().then(
      ressources => dispatch(success(ressources)),
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: ressourceConstants.GET_ALL_REQUEST };
  }

  function success(ressources) {
    return { type: ressourceConstants.GET_ALL_SUCCESS, ressources };
  }

  function failure(error) {
    return { type: ressourceConstants.GET_ALL_FAILURE, error };
  }
}
