import { useEffect, useState } from "react";

import { sectorServices } from "services";

const AttributesLogic = () => {

	const limitAttributes = 20

	const [refresh, setRefresh] = useState(false);

	const [openNotif, setOpenNotif] = useState(false);
	const [notifMessage, setNotifMessage] = useState("");
	const [notifColor, setNotifColor] = useState("info");

	const [page, setPage] = useState(1)
	const [attributesList, setAttributesList] = useState(undefined)
	const [attributesCount, setAttributesCount] = useState(undefined)

	const [editModalOpened, setEditModalOpened] = useState(false);
	const [newAttributesInfo, setNewAttributesInfo] = useState(undefined);
	const [modalType, setModalType] = useState(false);

	const [attributeDestroy, setAttributeDestroy] = useState(undefined);

	const toggleConfirmAttributeDestroy = attribute => setAttributeDestroy(attribute);

	useEffect(() => {
		(async () => {
			const attribute = await sectorServices.getAll();
			if (attribute) {
				setAttributesCount(attribute.length)
				setAttributesList(attribute.slice(limitAttributes * (page - 1), limitAttributes * (page - 1) + limitAttributes))
			}
		})();
	}, [refresh, page]);

	const refreshData = () => setRefresh(!refresh);

	const handlePagination = page => {
		setPage(page);
	};

	const openNotification = (msg, color = "info", time = 6) => {
		setOpenNotif(false);
		setNotifMessage(msg);
		setNotifColor(color);
		setTimeout(() => setOpenNotif(false), time * 1000);
		setTimeout(() => setOpenNotif(true), 100);
	};

	const deleteAttribute = async uuid => {
		await sectorServices.destroy(uuid)
		openNotification(`Le bâtiment ${attributesList.find(it => it.uuid === uuid)?.name} a été supprimé`, 'success')
		toggleConfirmAttributeDestroy(null)
		refreshData()
	};

	const toggleModal = async (attribute = null) => {
		setModalType(attribute ? true : false);
		const attributeInfo = {
			uuid: attribute?.uuid,
			title: attribute?.name ? attribute?.name : '',
			streetNumber: attribute?.streetNumber ? attribute?.streetNumber : '',
			streetName: attribute?.streetName ? attribute?.streetName : '',
		};
		setNewAttributesInfo(attributeInfo);
		setEditModalOpened(!editModalOpened);
	};

	const handleFormChange = (key, value) => {
		let attributeInfo = { ...newAttributesInfo };
		attributeInfo[key] = value;
		setNewAttributesInfo(attributeInfo);
	};

	const submitNewAttribute = async event => {
		event.preventDefault();
		if (newAttributesInfo.uuid) {
			const updated = await sectorServices.update(newAttributesInfo.uuid, newAttributesInfo.title, newAttributesInfo.streetNumber, newAttributesInfo.streetName);
			if (updated) {
				openNotification(`Le bâtiment ${newAttributesInfo?.title} a été modifié`, 'success')
			} else {
				openNotification(`Erreur, une erreur est survenu lors de la modification du bâtiment ${newAttributesInfo?.title}`, 'danger')
			}
		} else {
			const added = await sectorServices.add(newAttributesInfo.title, newAttributesInfo.streetNumber, newAttributesInfo.streetName);
			if (added) {
				openNotification(`Le bâtiment ${newAttributesInfo?.title} a été ajouté`, 'success')
			} else {
				openNotification(`Erreur, une erreur est survenu lors de l'ajout du bâtiment ${newAttributesInfo?.title}`, 'danger')
			}
		}
		setEditModalOpened(!editModalOpened);
		refreshData();
	};

	return {
		attributeDestroy,
		attributesCount,
		attributesList,
		editModalOpened,
		limitAttributes,
		modalType,
		newAttributesInfo,
		notifColor,
		notifMessage,
		openNotif,
		page,
		deleteAttribute,
		handleFormChange,
		handlePagination,
		refreshData,
		setOpenNotif,
		submitNewAttribute,
		toggleConfirmAttributeDestroy,
		toggleModal
	}
}

export default AttributesLogic