import React, { useEffect } from "react";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import {
	Button as MUIButton,
	Modal,
	Backdrop
} from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import Pagination from "@material-ui/lab/Pagination";
import Table from "components/Table/Table.js";
import {makeStyles} from "@material-ui/core/styles";
import {ReactComponent as AddIcon} from "assets/img/svg/AddIcon.svg";
import styles from "assets/jss/material-dashboard-react/views/eventStyle.js";
import A1001ListLogic from "./../TabLogics/A1001ListLogic";
import ConfirmModal from "components/ConfirmModal";
import FormA1001 from "components/FormUsers/FormA1001.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { Brightness1 } from "@material-ui/icons";

const paginationStyles = makeStyles(() => ({
		ul: {
				"& .MuiPaginationItem-root": {
						color: "#6E6FA9",
				},
				"& .Mui-selected": {
						backgroundColor: "#6E6FA9",
						color: "#E7E6ED",
				},
		},
}));

const useStyles = makeStyles(styles);

export default function A1001ListView(props) {
	
	const paginationClasses = paginationStyles();
	const classes = useStyles();
	
	const {
		bookingDestroy,
		toggleConfirmBookingDestroy,
		deleteBooking,
		modalMessageBooking,
		a1001List,
		a1001ModalOpened,
		newA1001Info,
		toggleModalA1001,
		formA1001Valid,
		modalA1001Type,
		handleA1001FormChange,
		requestNewIcsKey,
		submitNewA1001,
		a1001Count,
		limits,
		pageA1001,
		handlePaginationA1001,
		refreshData,
		notifColor,
		notifMessage,
		openNotif,
		setOpenNotif
	} = A1001ListLogic();
	
	useEffect(() => {
		refreshData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.refresh]);
	
	return (
		<>
		<Snackbar
				place="tr"
				message={notifMessage}
				open={openNotif}
				close
				icon={
						notifColor === "success"
								? CheckCircleIcon
								: notifColor === "danger"
										? WarningIcon
										: NotificationsIcon
				}
				closeNotification={() => setOpenNotif(false)}
				color={notifColor}
		/>
		{bookingDestroy && (
				<Modal
						BackdropComponent={Backdrop}
						BackdropProps={{timeout: 500}}
						className={classes.modal}
						onClose={() => toggleConfirmBookingDestroy(null)}
						open={!!bookingDestroy}
				>
						<div className={classes.paper}>
								<ConfirmModal
										closeModal={() => toggleConfirmBookingDestroy(null)}
										kind={modalMessageBooking()}
										makeAction={() => deleteBooking(bookingDestroy.uuid)}
								/>
						</div>
				</Modal>
		)}
		{a1001ModalOpened && newA1001Info && (
				<Modal
						BackdropComponent={Backdrop}
						BackdropProps={{
								timeout: 500,
						}}
						className={classes.modal}
						onClose={() => toggleModalA1001()}
						open={a1001ModalOpened}
				>
						<div
								className={classes.paper}
								style={{
										maxHeight: "80%",
								}}
						>
								<FormA1001
										formA1001Valid={formA1001Valid}
										modalA1001Type={modalA1001Type}
										newA1001Info={newA1001Info}
										handleA1001FormChange={handleA1001FormChange}
										requestNewIcsKey={requestNewIcsKey}
										submitNewA1001={submitNewA1001}
										toggleModalA1001={toggleModalA1001}
								/>
						</div>
				</Modal>
		)}
		<GridItem xs={12} sm={12} md={12}>
				<CardBody>
						<GridContainer>
								<GridItem xs={12} sm={12} md={12}>
										<MUIButton
												className={classes.button}
												onClick={() => toggleModalA1001()}
												startIcon={<AddIcon/>}
												style={{width: "auto"}}
										>
												Ajouter un AXIS A1001
										</MUIButton>
								</GridItem>
						</GridContainer>
				</CardBody>
				<GridItem xs={12} sm={12} md={12}>
						{a1001List && a1001List.length === 0 && (
								<h4>Aucun AXIS A1001 à afficher</h4>
						)}
						{a1001List && a1001List.length > 0 && (
								<Table
										tableHeaderColor="primary"
										tableType="booking"
										tableHead={[
												"En ligne",
												"Titre",
												"IP / Domaine",
												"Port",
												"TLS faible",
												"Utilisateur",
												"Mot de passe",
												"",
										]}
										tableData={
												a1001List &&
												a1001List.map((a1001, i) => {
														const A1001List = {
																status: <Brightness1 style={{color: a1001?.isOnline === true ? "#30A04B" : (a1001?.isOnline === false ? "#E76E51" : "#AEADB0")}}/>,
																title: a1001?.title,
																host: a1001?.host,
																port: a1001?.port,
																is_weak_cert: `${a1001?.is_weak_cert ? "Oui" : "Non"}`,
																user: a1001?.username,
																password: (
																		<div
																				style={{
																						display: "flex-end",
																				}}
																		>
																				<Button
																						size="sm"
																						onClick={() => {
																								const isChromium = !!window.chrome;
																								const copyToClipboard = (text) => {
																										navigator.clipboard.writeText(text).then(() => {
																												console.log("Copied to Clipboard Succefully");
																										}, () => {
																												console.error("Clipboard Copy Failed");
																										});
																								}
																								if (isChromium){
																										navigator.permissions.query({name: "clipboard-write"}).then((result) => {
																											if (result.state === "granted" || result.state === "prompt") {
																													copyToClipboard(a1001.password)
																											}else{
																													console.warn("User did not authorized clipboard copy");
																											}
																										});
																								}else{
																										copyToClipboard(a1001.password)
																								}
																										
																						}}
																						color="primary"
																				>Copier</Button>
																		</div>
																),
																actions: (
																		<div
																				style={{
																						display: "flex-end",
																				}}
																		>
																				<Button
																						size="sm"
																						target="_blank"
																						href={`https://${a1001.host}:${a1001.port}`}
																						color="primary"
																				>
																						Accéder
																				</Button>
																				<Button
																						onClick={() => toggleModalA1001(a1001)}
																						style={{marginLeft: "2%"}}
																						size="sm"
																						color="warning"
																				>
																						Éditer
																				</Button>
																				<Button
																						style={{marginLeft: "2%"}}
																						size="sm"
																						onClick={() =>
																								toggleConfirmBookingDestroy(
																										"a1001",
																										a1001
																								)
																						}
																						color="danger"
																				>
																						Supprimer
																				</Button>
																		</div>
																),
														};
														return Object.values(A1001List);
												})
										}
								/>
						)}
						{a1001Count > 0 && (
								<div
										style={{
												display: "flex",
												justifyContent: "center",
												paddingTop: 100,
										}}
								>
										<Pagination
												count={Math.ceil(a1001Count / limits.a1001)}
												page={pageA1001}
												onChange={(event, pageNumber) => {
														handlePaginationA1001(pageNumber);
												}}
												classes={{ul: paginationClasses.ul}}
												size="large"
										/>
								</div>
						)}
				</GridItem>
		</GridItem>
		</>
	)
}