import { useEffect, useState } from "react";

import {
	sectorServices,
	groupServices
} from "services";

const GroupListLogic = () => {

	const limitGroups = 20

	const [refresh, setRefresh] = useState(false);

	const [openNotif, setOpenNotif] = useState(false);
	const [notifMessage, setNotifMessage] = useState("");
	const [notifColor, setNotifColor] = useState("info");

	const [page, setPage] = useState(1)
	const [groupsList, setGroupsList] = useState(undefined)
	const [groupsCount, setGroupsCount] = useState(undefined)

	const [editModalOpened, setEditModalOpened] = useState(false);
	const [newGroupsInfo, setNewGroupsInfo] = useState(undefined);
	const [formAttributesList, setFormAttributesList] = useState(undefined)
	const [modalType, setModalType] = useState(false);

	const [groupDestroy, setGroupDestroy] = useState(undefined);

	const toggleConfirmGroupDestroy = group => setGroupDestroy(group);

	useEffect(() => {
		(async () => {
			const attributes = await sectorServices.getAll()
			if (attributes) {
				setFormAttributesList(attributes)
			}
			const group = await groupServices.getAllByEstate()
			if (group) {
				setGroupsCount(group.length)
				setGroupsList(group.slice(limitGroups * (page - 1), limitGroups * (page - 1) + limitGroups))
			}
		})();
	}, [refresh, page]);

	const refreshData = () => setRefresh(!refresh);

	const handlePagination = page => {
		setPage(page);
	};

	const openNotification = (msg, color = "info", time = 6) => {
		setOpenNotif(false);
		setNotifMessage(msg);
		setNotifColor(color);
		setTimeout(() => setOpenNotif(false), time * 1000);
		setTimeout(() => setOpenNotif(true), 100);
	};

	const deleteGroup = async uuid => {
		await groupServices.destroy(uuid)
		openNotification(`L'appartement ${groupsList.find(it => it.uuid === uuid)?.title} a été supprimé`, 'success')
		toggleConfirmGroupDestroy(null)
		refreshData()
	};

	const toggleModal = async (group = null) => {
		setModalType(group ? true : false);
		const groupInfo = {
			uuid: group?.uuid,
			title: group?.title ? group?.title : '',
			attribute: group?.sector?.uuid ? group?.sector?.uuid : ''
		};
		setNewGroupsInfo(groupInfo);
		setEditModalOpened(!editModalOpened);
	};

	const handleFormChange = (key, value) => {
		let groupInfo = { ...newGroupsInfo };
		groupInfo[key] = value;
		setNewGroupsInfo(groupInfo);
	};

	const submitNewGroup = async event => {
		event.preventDefault();
		if (newGroupsInfo.uuid) {
			if (await groupServices.update(newGroupsInfo.uuid, newGroupsInfo.title, newGroupsInfo.attribute)) {
				openNotification(`L'appartement ${newGroupsInfo?.title} a été modifié`, 'success')
			} else {
				openNotification(`Erreur, une erreur est survenu lors de la modification de l'appartement ${newGroupsInfo?.title}`, 'danger')
			}
		} else {
			if (await groupServices.add(newGroupsInfo.title, newGroupsInfo.attribute)) {
				openNotification(`L'appartement ${newGroupsInfo?.title} a été ajouté`, 'success')
			} else {
				openNotification(`Erreur, une erreur est survenu lors de l'ajout de l'appartement ${newGroupsInfo?.title}`, 'danger')
			}
		}
		setEditModalOpened(!editModalOpened);
		refreshData();
	};

	return {
		editModalOpened,
		formAttributesList,
		groupDestroy,
		groupsCount,
		groupsList,
		limitGroups,
		modalType,
		newGroupsInfo,
		notifColor,
		notifMessage,
		openNotif,
		page,
		deleteGroup,
		handleFormChange,
		handlePagination,
		refreshData,
		setOpenNotif,
		submitNewGroup,
		toggleConfirmGroupDestroy,
		toggleModal
	}
}

export default GroupListLogic