import { useEffect, useState } from "react";
import { useSelector } from "react-redux"; // @material-ui/core components
import moment from "moment";
import localization from "moment/locale/fr";

import {
	reservationSubscriptionServices,
} from "services";

moment.updateLocale("fr", localization);

const SubscriptionListLogic = () => {

	const [refresh, setRefresh] = useState(false);

	const { myself } = useSelector(state => state.myself);
	const limits = {
		subscription: 15,
		rule: 4,
	}
	const [subscriptions, setSubscriptions] = useState(undefined)
	const [subscriptionsCount, setSubscriptionsCount] = useState(0)
	const [pageSubscription, setPageSubscription] = useState(1)
	const statusSub = [
		{ id: 1, title: 'Initié', key: 'initiated' },
		{ id: 2, title: 'En cours', key: 'ongoing' },
		{ id: 3, title: 'Dépassé', key: 'outdated' },
		{ id: 4, title: 'Annulé', key: 'canceled' },
	]
	const [subscriptionStatus, setSubscriptionStatus] = useState(statusSub.filter(it => it.key === 'ongoing' || it.key === 'initiated'))

	const refreshData = () => setRefresh(!refresh);

	useEffect(() => {
		(async () => {
			let sub = await reservationSubscriptionServices.getAll()
			if (sub) {
				sub = sub.filter(s => !subscriptionStatus.length || subscriptionStatus.find(it => it.key === s.status))
				setSubscriptionsCount(sub.length)
				setSubscriptions(sub.slice(limits.subscription * (pageSubscription - 1), limits.subscription * (pageSubscription - 1) + limits.subscription))
			}
		})()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageSubscription, refresh])

	const handlePaginationSubscription = page => {
		setPageSubscription(page);
	};

	const updateSubscriptionStatusFilter = (value) => {
		setSubscriptionStatus(value)
		refreshData()
	}

	return {
		limits,
		myself,
		pageSubscription,
		statusSub,
		subscriptions,
		subscriptionsCount,
		subscriptionStatus,
		handlePaginationSubscription,
		refreshData,
		updateSubscriptionStatusFilter,
	};

};

export default SubscriptionListLogic;
