import { useEffect, useState } from "react";

import { incidentCategoryServices } from "services";

const IncidentsSettingsLogic = () => {

  const [allCategoryList, setAllCategoryList] = useState(undefined);

  const [openNotif, setOpenNotif] = useState(false)
  const [notifMessage, setNotifMessage] = useState('')
  const [notifColor, setNotifColor] = useState('info')


  useEffect(() => {
    (async () => {
      const allCategories = await incidentCategoryServices.fetchForSettings()
      if (allCategories) {
        setAllCategoryList(allCategories);
      }
    })();
  }, []);

  const handleChangeCategory = (e, category) => {
    const newCategoryList = allCategoryList.slice(0)
    newCategoryList.find(it => it.id === category.id).activated = e.target.checked
    setAllCategoryList(newCategoryList)
  }

  const submitCategoriesSettings = async () => {
    setOpenNotif(false)
    const allCategories = await incidentCategoryServices.fetchForSettings()
    if (allCategories) {
      allCategories.map(async (it) => {
        const category = allCategoryList.filter(c => c.id === it.id && c.activated !== it.activated)
        if (category?.length) {
          if (category[0].activated)
            await incidentCategoryServices.activateForSettings(category[0].uuid)
          else
            await incidentCategoryServices.deactivateForSettings(category[0].uuid)
        }
      })
      setNotifMessage(`Les paramètres des catégories ont été enregistré`)
      setNotifColor('success')
      setOpenNotif(true)
      setTimeout(() => setOpenNotif(false), 6000);
    } else {
      NotificationError("lors de la validation des paramètres des catégories")
    }
  }

  const NotificationError = (msg=null) => {
    setOpenNotif(false)
    setNotifMessage(`Une erreur est survenu${msg ? ' ' : ''}${msg}.\nVeuillez ressayer ultérieurement`)
    setNotifColor('danger')
    setTimeout(() => setOpenNotif(false), 6000);
    setTimeout(() => setOpenNotif(true), 100)
  }

  return {
    allCategoryList,
    notifColor,
    notifMessage,
    openNotif,
    handleChangeCategory,
    setOpenNotif,
    submitCategoriesSettings,
  }
}

export default IncidentsSettingsLogic