import { get_axs, post_axs, put_axs, remove_axs } from "helpers/fetcher";

export const plannedClosureServices = {
	getAll,
	update,
	add,
	destroy
};

const basePath = "/modules/reservations/planned-closure";

async function getAll() {
	const data = get_axs(basePath);
	return data;
}

async function update(uuid, data) {
	return put_axs(
		`${basePath}/${uuid}`,
		new URLSearchParams({
			...data,
		})
	);
}

async function add(data) {
	return post_axs(
		basePath,
		new URLSearchParams({
			...data,
		})
	);
}

async function destroy(uuid) {
	return remove_axs(`${basePath}/${uuid}`);
}