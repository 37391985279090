import { get_axs, post_axs, put_axs, remove_axs } from "helpers/fetcher";

export const reservationRessourceServices = {
  getAllRessources,
  getItem,
  getAvailability,
  getCalendarInfo,
  update,
  add,
  setTypeForRessource,
  setLocationForRessource,
  setCleaningForRessource,
  setRuleForRessource,
  destroy,
  attachFeature,
  detachFeature,
  setImage,
};

async function getAllRessources() {
  const wsData = get_axs("/modules/reservations/ressources");
  return wsData;
}

async function getItem(uuid) {
  return get_axs(`/modules/reservations/ressources/${uuid}`);
}

async function getAvailability(uuid) {
  return get_axs(`/modules/reservations/ressources/${uuid}/available`);
}

async function getCalendarInfo(uuid) {
  return get_axs(`/modules/reservations/ressources/${uuid}/calendar/info`);
}

async function update(uuid, data) {
  return put_axs(
    `/modules/reservations/ressources/${uuid}`,
    new URLSearchParams({
      ...data,
    })
  );
}

async function add(data) {
  return post_axs(
    "/modules/reservations/ressources",
    new URLSearchParams({
      ...data,
    })
  );
}

async function setLocationForRessource(locationUUID, uuid) {
  return put_axs(
    `/modules/reservations/ressources/${uuid}/location`,
    new URLSearchParams({
      location_uuid: locationUUID,
    })
  );
}

async function setCleaningForRessource(cleaningUUID, uuid) {
  return put_axs(
    `/modules/reservations/ressources/${uuid}/cleaning`,
    new URLSearchParams({
      cleaning_uuid: cleaningUUID,
    })
  );
}

async function setTypeForRessource(keyid, uuid) {
  return put_axs(
    `/modules/reservations/ressources/${uuid}/type`,
    new URLSearchParams({
      type_keyid: keyid,
    })
  );
}

async function setRuleForRessource(ruleUUID, uuid) {
  return put_axs(
    `/modules/reservations/ressources/${uuid}/rule`,
    new URLSearchParams({
      rule_uuid: ruleUUID,
    })
  );
}

async function destroy(uuid) {
  return remove_axs(`/modules/reservations/ressources/${uuid}`);
}

async function attachFeature(uuid, featureUUID) {
  return post_axs(
    `/modules/reservations/ressources/${uuid}/feature`,
    new URLSearchParams({
      feature_uuid: featureUUID,
    })
  );
}

async function detachFeature(uuid, featureUUID) {
  return remove_axs(
    `/modules/reservations/ressources/${uuid}/feature`,
    new URLSearchParams({
      feature_uuid: featureUUID,
    })
  );
}

async function setImage(uuid, data) {
  return post_axs(
    `/modules/reservations/ressources/${uuid}`,
    data,
    "multipart/form-data"
  );
}
