import { get_axs, post_axs, put_axs, remove_axs } from "helpers/fetcher";

export const reservationLocationServices = {
  add,
  update,
  destroy,
  getAll,
  getItem,
  setCleaningToLocation,
  unsetCleaningForLocation,
  setSectionToLocation,
  unsetSectionForLocation,
  setImage,
};

const basePath = "/modules/reservations/locations";

async function add(data) {
  return post_axs(
    basePath,
    new URLSearchParams({
      ...data,
    })
  );
}

async function update(uuid, data) {
  return put_axs(
    `${basePath}/${uuid}`,
    new URLSearchParams({
      ...data,
    })
  );
}

async function setCleaningToLocation(cleaningUUID, uuid) {
  return put_axs(
    `${basePath}/${uuid}/set/cleaning/${cleaningUUID}`
  );
}

async function unsetCleaningForLocation(uuid) {
  return put_axs(`${basePath}/${uuid}/unset/cleaning`);
}

async function setSectionToLocation(sectionUUID, uuid) {
  return put_axs(
    `${basePath}/${uuid}/set/section/${sectionUUID}`
  );
}

async function unsetSectionForLocation(uuid) {
  return put_axs(`${basePath}/${uuid}/unset/section`);
}

async function getAll() {
  return get_axs(basePath);
}

async function getItem(uuid) {
  return get_axs(`${basePath}/${uuid}`);
}

async function destroy(uuid) {
  return remove_axs(`${basePath}/${uuid}`);
}

async function setImage(uuid, data) {
  return post_axs(
    `${basePath}/${uuid}`,
    data,
    'multipart/form-data'
  );
}