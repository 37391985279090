import { get_axs, post_axs, put_axs, remove_axs, get_cdn } from "helpers/fetcher";

export const linkPointServices = {
  getAll,
  getItem,
  add,
  update,
  setImage,
  unsetImage,
  getImage,
  destroy,
  attachGroup,
  detachGroup,
  attachFeature,
  detachFeature,
};

async function getAll() {
  return get_axs("/modules/links/points");
}

async function getItem(uuid) {
  return get_axs(`/modules/links/points/${uuid}`);
}

async function add(data) {
  return post_axs(
    "/modules/links/points",
    new URLSearchParams({
      ...data
    })
  );
}

async function update(pointUUID, data) {
  return put_axs(
    `/modules/links/points/${pointUUID}`,
    new URLSearchParams({
      ...data
    })
  );
}

async function setImage(pointUUID, image) {
  return post_axs(
    `/modules/links/points/${pointUUID}/image`,
    image,
    'multipart/form-data'
  );
}

async function unsetImage(pointUUID) {
  return remove_axs(`/modules/links/points/${pointUUID}/image`);
}

async function getImage(pointUUID) {
  return get_cdn(`/app/module/links/points/${pointUUID}/image`);
}

async function destroy(pointUUID) {
  return remove_axs(`/modules/links/points/${pointUUID}`);
}

async function attachGroup(pointUUID, groupUUID) {
  return post_axs(`/modules/links/points/${pointUUID}/link/group/${groupUUID}`);
}

async function detachGroup(pointUUID) {
  return remove_axs(`/modules/links/points/${pointUUID}/link/group`);
}

async function attachFeature(uuid, featureUUID) {
  return post_axs(
    `/modules/links/points/${uuid}/feature`,
    new URLSearchParams({
      feature_uuid: featureUUID,
    })
  );
}

async function detachFeature(uuid, featureUUID) {
  return remove_axs(
    `/modules/links/points/${uuid}/feature`,
    new URLSearchParams({
      feature_uuid: featureUUID,
    })
  );
}
