import { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";

import { linkFileServices } from "services";

import { useDropzone } from "react-dropzone";

import { saveAs } from 'file-saver'
import FileType from 'file-type/browser'

const FilesLogic = () => {

  const { myself } = useSelector(userState => userState.myself);

  const limitFile = 10

  const [refresh, setRefresh] = useState(false);

  const [openNotif, setOpenNotif] = useState(false);
  const [notifMessage, setNotifMessage] = useState("");
  const [notifColor, setNotifColor] = useState("info");

  const [file, setFile] = useState(undefined);
  const [fileList, setFileList] = useState(undefined);
  const [fileCount, setFileCount] = useState(0)
  const [page, setPage] = useState(1)
  const [newFileTitle, setNewFileTitle] = useState(undefined)

  const [fileDestroy, setFileDestroy] = useState(undefined);

  const toggleConfirmFileDestroy = file => setFileDestroy(file);

  useEffect(() => {
    (async () => {
      const files = await linkFileServices.getAll()
      if (files) {
        setFileCount(files.length)
        setFileList(files.slice(limitFile * (page - 1), limitFile * (page - 1) + limitFile))
      }
    })();
  }, [refresh, page]);

  const refreshData = () => setRefresh(!refresh);

  const handlePagination = page => {
    setPage(page);
  };

  const openNotification = (msg, color = "info", time = 6) => {
    setOpenNotif(false);
    setNotifMessage(msg);
    setNotifColor(color);
    setTimeout(() => setOpenNotif(false), time * 1000);
    setTimeout(() => setOpenNotif(true), 100);
  };

  const uploadfile = async () => {
    if (fileList.find(f => f.name === newFileTitle)) {
      openNotification('Erreur, le fichier existe déjà', 'danger')
      return
    }
    let data = new FormData();
    data.append('file', file)
    if (await linkFileServices.add(data, newFileTitle)) {
      openNotification(`Le fichier ${newFileTitle} a été transféré`, 'success')
    } else {
      openNotification(`Erreur, une erreur est survenue lors de l'envoi du fichier: ${newFileTitle}`, 'danger')
    }
    setNewFileTitle(undefined)
    setFile(undefined)
    refreshData()
  }

  const deleteFile = async (uuid) => {
    await linkFileServices.destroy(uuid)
    openNotification(`${fileList.find(f => f.uuid === uuid)?.name} a été supprimé`, 'success')
    toggleConfirmFileDestroy(null)
    refreshData()
  }

  const downloadFile = async (file) => {
    const res = await linkFileServices.download(file.uuid)
    const blob = new Blob([res])
    const type = await FileType.fromBlob(blob)
    saveAs(blob, file.name.endsWith("." + type.ext) ? file.name : file.name + "." + type.ext)
  }

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length === 1) {
      setNewFileTitle(acceptedFiles[0].name)
      setFile(acceptedFiles[0]);
    }
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    type: "file",
    onDrop,
    maxFiles: 1,
  });

  const handleTitleChange = (title) => {
    setNewFileTitle(title)
  }

  return {
    file,
    fileCount,
    fileDestroy,
    fileList,
    isDragActive,
    isDragAccept,
    isDragReject,
    limitFile,
    myself,
    newFileTitle,
    notifColor,
    notifMessage,
    openNotif,
    page,
    deleteFile,
    downloadFile,
    getInputProps,
    getRootProps,
    handleTitleChange,
    handlePagination,
    refreshData,
    setOpenNotif,
    toggleConfirmFileDestroy,
    uploadfile,
  }
}

export default FilesLogic