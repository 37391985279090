import { get_axs, post_axs, put_axs, remove_axs } from "helpers/fetcher";

export const newsCategoryServices = {
  getAll,
  add,
  update,
  destroy,
};

async function getAll() {
  return get_axs("/modules/news/categories");
}

async function update(uuid, title) {
  return put_axs(
    `/modules/news/categories/${uuid}`,
    new URLSearchParams({
      title,
    })
  );
}

async function add(title) {
  return post_axs(
    `/modules/news/categories`,
    new URLSearchParams({
      title,
    })
  );
}

async function destroy(uuid) {
  return remove_axs(`/modules/news/categories/${uuid}`);
}
