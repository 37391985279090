import React, { useEffect } from "react";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import { ReactComponent as AddIcon } from "assets/img/svg/AddIcon.svg";
import Button from "components/CustomButtons/Button.js";
import Table from "components/Table/Table.js";
import Pagination from "@material-ui/lab/Pagination";
import GroupListLogic from "../TabLogics/GroupListLogic";
import styles from "assets/jss/material-dashboard-react/views/newsStyle.js";
import CloseIcon from "@material-ui/icons/Close";
import Skeleton from "@material-ui/lab/Skeleton";
import { IconButton } from "@material-ui/core";
import ConfirmModal from "components/ConfirmModal";
import Snackbar from "components/Snackbar/Snackbar.js";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import NotificationsIcon from '@material-ui/icons/Notifications';

import {
	Button as MUIButton,
	Chip,
	makeStyles,
	Backdrop,
	Container,
	Typography,
	Grid,
	TextField,
	Modal,
	CssBaseline,
	MenuItem,
	Select,
	InputLabel
} from "@material-ui/core";

const paginationStyles = makeStyles(() => ({
	ul: {
		"& .MuiPaginationItem-root": {
			color: "#6E6FA9",
		},
		"& .Mui-selected": {
			backgroundColor: "#6E6FA9",
			color: "#E7E6ED",
		},
	},
}));

const useStyles = makeStyles(styles);

export default function GroupListView(props) {
	
	const classes = useStyles();
	const paginationClasses = paginationStyles();
	
	const {
		toggleModal,
		groupsList,
		groupsCount,
		toggleConfirmGroupDestroy,
		limitGroups,
		handlePagination,
		page,
		editModalOpened,
		newGroupsInfo,
		modalType,
		submitNewGroup,
		handleFormChange,
		formAttributesList,
		groupDestroy,
		deleteGroup,
		notifColor,
		notifMessage,
		openNotif,
		setOpenNotif,
		refreshData
	} = GroupListLogic();
	
	useEffect(() => {
		refreshData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.refresh]);
	
	return (
		<>
		<Snackbar
			place='tr'
			message={notifMessage}
			open={openNotif}
			close
			icon={notifColor === 'success' ? CheckCircleIcon : (notifColor === 'danger' ? WarningIcon : NotificationsIcon)}
			closeNotification={() => setOpenNotif(false)}
			color={notifColor}
		/>
		{groupDestroy && (
			<Modal
				BackdropComponent={Backdrop}
				BackdropProps={{ timeout: 500 }}
				className={classes.modal}
				onClose={() => toggleConfirmGroupDestroy(null)}
				open={groupDestroy ? true : false}
			>
				<div className={classes.paper}>
					<ConfirmModal
						closeModal={() => toggleConfirmGroupDestroy(null)}
						kind={`Supprimer l'appartement "${groupDestroy.title}" ?`}
						makeAction={() => deleteGroup(groupDestroy.uuid)}
					/>
				</div>
			</Modal>
		)}
		{editModalOpened && newGroupsInfo && (
			<Modal
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
				className={classes.modal}
				onClose={() => toggleModal()}
				open={editModalOpened}
			>
				<div className={classes.paper}>
					<Container component="main" maxWidth="md" style={{
						overflowY: "auto",
					}}>
						<CssBaseline />
						<div>
							<div className={classes.headerModal}>
								{modalType ? (
									<Typography variant="h5">Modifier l'appartement</Typography>
								) : (
									<Typography variant="h5">Ajouter un appartement</Typography>
								)}
								<IconButton
									size="small"
									aria-label="delete"
									onClick={() => toggleModal()}
								>
									<CloseIcon />
								</IconButton>
							</div>
							<form className={classes.form} onSubmit={submitNewGroup} noValidate>
								<Grid container spacing={3}>
									<Grid item md={12}>
										<InputLabel>Titre *</InputLabel>
										<TextField
											autoComplete="fname"
											defaultValue={newGroupsInfo.title}
											name="title"
											required
											fullWidth
											id="title"
											onChange={event =>
												handleFormChange("title", event.target.value)
											}
											autoFocus
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<InputLabel>Secteur *</InputLabel>
										{formAttributesList ? (
											<Select
												value={newGroupsInfo.attribute}
												style={{ top: "16px" }}
												id="select-type"
												fullWidth
												displayEmpty
												onChange={event =>
													handleFormChange("attribute", event.target.value)
												}
											>
												<MenuItem value={""}>
													<em>Aucune séléction</em>
												</MenuItem>
												{formAttributesList &&
													formAttributesList.map((it, i) => (
														<MenuItem key={i} value={it.uuid}>
															<div>{it.name}</div>
														</MenuItem>
													))}
											</Select>
										) : (
											<Typography variant="h3">
												<Skeleton width="auto" />
											</Typography>
										)}
									</Grid>
								</Grid>
								<Button
									type="submit"
									fullWidth
									variant="contained"
									color="primary"
									className={classes.submit}
									disabled={!newGroupsInfo?.title?.length || !newGroupsInfo?.attribute?.length}
								>
									Enregister
								</Button>
							</form >
						</div >
					</Container >
				</div>
			</Modal>
		)}
		<GridItem xs={12} sm={12} md={12}>
			<CardBody>
				<GridContainer>
					<GridItem xs={12} sm={12} md={12}>
						<MUIButton
							className={classes.button}
							onClick={() => toggleModal()}
							startIcon={<AddIcon />}
						>
							Ajouter un appartement
						</MUIButton>
					</GridItem>
				</GridContainer>
			</CardBody>
		
			<div>
				{groupsList && groupsList.length === 0 ? (
					<h4>Aucun appartement à afficher</h4>
				) :
					(<Table
						tableHeaderColor="primary"
						tableType="links"
						tableHead={
							[
								"Titre",
								"Secteur",
								"Membres",
								""
							]
						}
						tableData={
							groupsList &&
							groupsList.map(group => {
								
								const groupList = {
									title: group?.title,
									sector: group?.sector?.name,
									members: group?.users?.map(item => {
										const linkState = item.app_site_to_user?.state;
										if (linkState === 'accepted'){
											return (
												<Chip color="primary" label={`${item.first_name} ${item.last_name}`} />
											)
										}else{
											return (
												<Chip label={`${item.first_name} ${item.last_name}`} />
											)
										}
									}),
									actions: (
										<div style={{
											display: "flex",
										}}>
											<Button
												style={{ marginLeft: 'auto', }}
												size="sm"
												onClick={() => toggleModal(group)}
												color="warning"
											>
												Éditer
											</Button>
											<Button
												size="sm"
												style={{ marginLeft: '2%' }}
												onClick={() => toggleConfirmGroupDestroy(group)}
												color="danger"
											>
												Supprimer
											</Button>
										</div>
									),
								};
								return Object.values(groupList);
							})
						}
					/>)
				}
				{groupsCount > 0 && (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							paddingTop: 100,
						}}
					>
						<Pagination
							count={Math.ceil(groupsCount / limitGroups)}
							page={page}
							onChange={(event, pageNumber) => {
								handlePagination(pageNumber);
							}}
							classes={{ ul: paginationClasses.ul }}
							size="large"
						/>
					</div>
				)}
			</div>
		</GridItem>
		</>
	)
}