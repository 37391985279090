import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Drawer from "@material-ui/core/Drawer";

import { GetApp } from "@material-ui/icons";

import Button from "components/CustomButtons/Button.js";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import moment from "moment";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const Row = ({
  row,
  toggleDrawer,
  myself,
  downloadProofOfResidence,
  handleClickOpen,
  handleUpdateOccupationStatus,
  userState,
  rgpd
}) => {
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        {!rgpd ? (
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)
            }>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        ) : <TableCell />}
        {!rgpd && (
          <TableCell component="th" scope="row">
            {row.callTitle === "MR"
              ? "Monsieur"
              : row.callTitle === "MM"
                ? "Madame"
                : "Non renseigné"}
          </TableCell>
        )}
        <TableCell align="right">{row.lastName}</TableCell>
        <TableCell align="right">{row.firstName}</TableCell>
        <TableCell align="right">{row.email}</TableCell>
        {!rgpd && (
          <TableCell align="right">
            {row.mobilePhone ? row.mobilePhone : "Non renseigné"}
          </TableCell>
        )}
        <TableCell align="right">
          {moment(row.createdAt).format("DD MMMM YYYY [à] HH[h]mm")}
        </TableCell>
        <TableCell align="right">
          {row.activatedAt
          ? moment(row.activatedAt).format("DD MMMM YYYY [à] HH[h]mm")
          : "Jamais"}
        </TableCell>
        {!rgpd && (
          <TableCell align="right">
            {row.logedinAt
              ? moment(row.logedinAt).format("DD MMMM YYYY [à] HH[h]mm")
              : "Jamais"}
          </TableCell>
        )}
        <TableCell align="right">
          {
            <div>
              {["right"].map(
                (anchor, key) =>
                  (myself?.rights?.role_superadmin ||
                    myself.rights.role_users_edit) && (
                    <React.Fragment key={key}>
                      <Button
                        onClick={toggleDrawer(anchor, true, row)}
                        size="sm"
                        color="warning"
                      >
                        Éditer
                      </Button>
                    </React.Fragment>
                  )
              )}
            </div>
          }
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Rattaché à :
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Bâtiment</TableCell>
                    <TableCell>Appartement</TableCell>
                    <TableCell>État</TableCell>
                    <TableCell>Statut d'occupation</TableCell>
                    <TableCell>Nom du propriétaire (si locataire)</TableCell>
                    <TableCell>Justificatif de domicile</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row?.estateToUsers?.filter(eTu => eTu.state === userState)
                    .map((data, key) => (
                      <TableRow key={key}>
                        <TableCell component="th" scope="row">
                          {data?.sector?.name}
                        </TableCell>
                        <TableCell>{data?.group?.title}</TableCell>
                        <TableCell>
                          {data?.state === "accepted" ? "Accepté" : "Désactivé"}
                        </TableCell>
                        <TableCell>
                          <Select
                            value={data?.occupationStatus}
                            onChange={event =>
                              handleUpdateOccupationStatus(
                                row.uid,
                                event.target.value,
                                data?.groupId
                              )
                            }
                            disabled={!(myself?.rights?.role_superadmin ||
                              myself.rights.role_users_edit)}
                          >
                            <MenuItem value={"OWNER"}>Propriétaire</MenuItem>
                            <MenuItem value={"TENANT"}>Locataire</MenuItem>
                          </Select>
                        </TableCell>

                        <TableCell>{data.ownerName || "N/A"}</TableCell>
                        <TableCell>
                          {data.occupationStatus === "TENANT" ? (
                            <IconButton
                              aria-label="download"
                              onClick={() =>
                                downloadProofOfResidence(
                                  data.uuid,
                                  data?.group?.id
                                )
                              }
                            >
                              <GetApp />
                            </IconButton>
                          ) : (
                            "Aucun"
                          )}
                        </TableCell>
                        <TableCell>
                          {(myself?.rights?.role_superadmin ||
                            myself.rights.role_users_edit) &&
                            (userState === "accepted" ? (
                              <Button
                                onClick={() => handleClickOpen("group", data?.group, row)}
                                size="sm"
                                color="danger"
                              >
                                Dissocier
                              </Button>
                            ) : (
                              <Button
                                onClick={() => handleClickOpen("group", data?.group, row)}
                                size="sm"
                                color="info"
                              >
                                Réassocier
                              </Button>
                            ))
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.object,
  toggleDrawer: PropTypes.func,
  myself: PropTypes.object,
  downloadProofOfResidence: PropTypes.func,
  handleClickOpen: PropTypes.func,
  handleUpdateOccupationStatus: PropTypes.func,
  userState: PropTypes.string,
  rgpd: PropTypes.bool
}

const CollapsibleTable = ({
  usersList,
  drawer,
  toggleDrawer,
  myself,
  sectors,
  currentUser,
  downloadProofOfResidence,
  handleClickOpen,
  handleUpdateOccupationStatus,
  sendNewPassword,
  toggleModalUserType,
  userState,
  rgpd
}) => {
  return (
    <div>
      <Drawer
        anchor={"right"}
        open={drawer["right"]}
        onClose={toggleDrawer("right", false, "")}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ButtonGroup
            variant="contained"
            color="primary"
            aria-label="contained primary button group"
            orientation="vertical"
          >
            {!rgpd && (
              <Button
                color="info"
                onClick={() => sendNewPassword(currentUser?.uid)}
                size="lg"
              >
                Nouveau mot de passe
              </Button>
            )}
            <Button
              color="success"
              onClick={() => toggleModalUserType(currentUser)}
              size="lg"
            >
              Modifier
            </Button>
            {!rgpd && (
              <Button
                onClick={() =>
                  handleClickOpen(
                    "addSector",
                    sectors,
                    currentUser
                  )
                }
                size="lg"
              >
                Associer à un bâtiment
              </Button>
            )}
            <Button
              color="danger"
              onClick={() =>
                handleClickOpen("removeUser", "", currentUser)
              }
              size="lg"
            >
              Exclure de la résidence
            </Button>
          </ButtonGroup>
        </div>
      </Drawer>
      <TableContainer component={Paper} style={{ marginTop: "2%", width: '100%' }}>
        <Table aria-label="collapsible table" style={{ width: '100%' }}>
          <TableHead>
            <TableRow>
              {/* TODO: display category ONLY if datas are available */}
              <TableCell />
              {!rgpd && (<TableCell>Civilité</TableCell>)}
              <TableCell align="right">Nom</TableCell>
              <TableCell align="right">Prénom</TableCell>
              <TableCell align="right">Email</TableCell>
              {!rgpd && (<TableCell align="right">Téléphone</TableCell>)}
              <TableCell align="right">Inscrit le</TableCell>
              <TableCell align="right">Activé le</TableCell>
              {!rgpd && (<TableCell align="right">Dernière connexion</TableCell>)}
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {usersList.map((row, key) => (
              <Row
                key={key}
                row={row}
                drawer={drawer}
                toggleDrawer={toggleDrawer}
                myself={myself}
                sectors={sectors}
                currentUser={currentUser}
                downloadProofOfResidence={downloadProofOfResidence}
                handleClickOpen={handleClickOpen}
                handleUpdateOccupationStatus={handleUpdateOccupationStatus}
                sendNewPassword={sendNewPassword}
                userState={userState}
                rgpd={rgpd}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

CollapsibleTable.propTypes = {
  usersList: PropTypes.array,
  drawer: PropTypes.object,
  toggleDrawer: PropTypes.func,
  myself: PropTypes.object,
  sectors: PropTypes.array,
  currentUser: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  downloadProofOfResidence: PropTypes.func,
  handleClickOpen: PropTypes.func,
  handleUpdateOccupationStatus: PropTypes.func,
  sendNewPassword: PropTypes.func,
  toggleModalUserType: PropTypes.func,
  userState: PropTypes.string,
  rgpd: PropTypes.bool
}

export default CollapsibleTable
