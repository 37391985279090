import { get_axs, post_axs, put_axs, remove_axs } from "helpers/fetcher";

export const linkGroupServices = {
  getAll,
  getItem,
  destroy,
  update,
  add,
};

async function getAll() {
  return get_axs("/modules/links/groups");
}

async function getItem(uuid) {
  return get_axs(`/modules/links/groups/${uuid}`);
}

async function destroy(uuid) {
  return remove_axs(`/modules/links/groups/${uuid}`);
}

async function update(uuid, title) {
  return put_axs(
    `/modules/links/groups/${uuid}`,
    new URLSearchParams({
      title,
    })
  );
}

async function add(title) {
  return post_axs(
    "/modules/links/groups",
    new URLSearchParams({
      title,
    })
  );
}
