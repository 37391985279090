import { siteConstants } from "constants/site.constants";
import { siteService } from "services";
import { modalActions } from "actions/modal.actions";
import { history } from "helpers";
import { saveState } from "helpers/localStorage";

export const siteActions = {
  deleteImage,
  getAll,
  getSite,
  update,
  uploadImage,
};

function getAll() {
  return dispatch => {
    dispatch(request());
    siteService.getAll().then(
      sites => {
        dispatch(success(sites));
        dispatch(modalActions.showSitesModal());
        saveState("sites", sites);

        history.push("/admin/statistics");
      },
      error => dispatch(failure(error))
    );
  };

  function request() {
    return { type: siteConstants.GETALL_REQUEST };
  }

  function success(list) {
    return { type: siteConstants.GETALL_SUCCESS, list };
  }

  function failure(error) {
    return { type: siteConstants.GETALL_FAILURE, error };
  }
}

function getSite(selectedSite) {
  return dispatch => {
    dispatch(request());
    siteService.getSite(selectedSite.uuid).then(
      site => {
        dispatch(success(site));
        dispatch(modalActions.hideSitesModal());
      },
      error => {
        dispatch(failure(error));
        alert("Error getting site", error);
      }
    );
  };

  function request() {
    return { type: siteConstants.GETSITE_REQUEST };
  }

  function success(item) {
    return { type: siteConstants.GETSITE_SUCCESS, item };
  }

  function failure(error) {
    return { type: siteConstants.GETSITE_FAILURE, error };
  }
}

function update(siteToUpdate) {
  return dispatch => {
    dispatch(request(siteToUpdate));
    siteService.update(siteToUpdate).then(
      response => dispatch(success(response)),
      error => dispatch(failure(error))
    );
  };

  function request(site) {
    return { type: siteConstants.UPDATE_REQUEST, site };
  }

  function success(response) {
    return { type: siteConstants.UPDATE_SUCCESS, response };
  }

  function failure(error) {
    return { type: siteConstants.UPDATE_FAILURE, error };
  }
}

function uploadImage(type, img) {
  return dispatch => {
    dispatch(request(img));
    siteService.setImage(type, img).then(
      response => dispatch(success(response)),
      error => dispatch(failure(error))
    );
  };

  function request(img) {
    return { type: siteConstants.UPLOAD_REQUEST, img };
  }

  function success(response) {
    return { type: siteConstants.UPLOAD_SUCCESS, response };
  }

  function failure(error) {
    return { type: siteConstants.UPLOAD_FAILURE, error };
  }
}

function deleteImage(type) {
  return dispatch => {
    dispatch(request());
    siteService.removeImage(type).then(
      response => dispatch(success(response)),
      error => dispatch(failure(error))
    );
  };

  function request() {
    return { type: siteConstants.IMG_DELETE_REQUEST };
  }

  function success(response) {
    return { type: siteConstants.IMG_DELETE_SUCCESS, response };
  }

  function failure(error) {
    return { type: siteConstants.IMG_DELETE_FAILURE, error };
  }
}