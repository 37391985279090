import { groupConstants } from "../constants";
import { alertActions } from ".";
import { groupServices } from "services";

export const groupActions = {
  getAllByEstate,
  getAllBySector,
};

function getAllByEstate() {
  return dispatch => {
    dispatch(request());
    groupServices.getAllByEstate().then(
      groups => dispatch(success(groups)),
    ).catch(error => {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    });
  };

  function request() {
    return { type: groupConstants.GETALL_REQUEST };
  }

  function success(groups) {
    return { type: groupConstants.GETALL_SUCCESS, groups };
  }

  function failure(error) {
    return { type: groupConstants.GETALL_FAILURE, error };
  }
}

function getAllBySector(id) {
  return dispatch => {
    dispatch(request());
    groupServices.getAllBySector(id).then(
      groups => dispatch(success(groups)),
    ).catch(error => {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    });
  };

  function request() {
    return { type: groupConstants.GETALL_REQUEST };
  }

  function success(groups) {
    return { type: groupConstants.GETALL_SUCCESS, groups };
  }

  function failure(error) {
    return { type: groupConstants.GETALL_FAILURE, error };
  }
}
