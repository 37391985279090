import { get_axs, post_axs, remove_axs } from "helpers/fetcher";

export const linkFileServices = {
  getAll,
  destroy,
  add,
  download,
};

async function getAll() {
  return get_axs("/modules/links/files");
}


async function destroy(uuid) {
  return remove_axs(`/modules/links/files/${uuid}`);
}

async function add(file, title) {
  return post_axs(
    `/modules/links/files?title=${title}`,
    file,
    'multipart/form-data',
  );
}

async function download(uuid) {
  return get_axs(`/modules/links/files/${uuid}`, undefined, undefined, 'blob');
}
