import { get_axs, put_axs, post_axs, remove_axs } from "helpers/fetcher";

export const classifiedSettingServices = {
  getFeatureRelations,
  setFeatureRelations,
  update,
  destroy,
};

async function getFeatureRelations() {
  return get_axs("/modules/classifieds/settings/feature/message");
}

async function setFeatureRelations(classifiedFeatureUUID, messageFeatureUUID) {
  return post_axs(
    "/modules/classifieds/settings/feature/message",
    new URLSearchParams({
      classified_feature_uuid: classifiedFeatureUUID,
      message_feature_uuid: messageFeatureUUID,
    })
  );
}

async function update(id, classifiedFeatureUUID, messageFeatureUUID) {
  return put_axs(
    `/modules/classifieds/settings/feature/message/${id}`,
    new URLSearchParams({
      classified_feature_uuid: classifiedFeatureUUID,
      message_feature_uuid: messageFeatureUUID,
    })
  );
}

async function destroy(id) {
  return remove_axs(`/modules/classifieds/settings/feature/message/${id}`);
}
