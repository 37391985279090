import React, { useEffect } from "react";
import LocationListLogic from "./../TabLogics/LocationListLogic"
import GridItem from "components/Grid/GridItem.js";
import {makeStyles} from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/eventStyle.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardBody from "components/Card/CardBody.js";
import {ReactComponent as AddIcon} from "assets/img/svg/AddIcon.svg";
import Button from "components/CustomButtons/Button.js";
import ConfirmModal from "components/ConfirmModal";
import Table from "components/Table/Table.js";
import Pagination from "@material-ui/lab/Pagination";
import FormLocations from "components/FormUsers/FormLocations.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
import NotificationsIcon from "@material-ui/icons/Notifications";
import {
		Button as MUIButton,
		CircularProgress,
		Backdrop,
		Modal,
} from "@material-ui/core";

const paginationStyles = makeStyles(() => ({
		ul: {
				"& .MuiPaginationItem-root": {
						color: "#6E6FA9",
				},
				"& .Mui-selected": {
						backgroundColor: "#6E6FA9",
						color: "#E7E6ED",
				},
		},
}));

const useStyles = makeStyles(styles);

export default function LocationListView(props) {
	
	const paginationClasses = paginationStyles();
	const classes = useStyles();
	const {
		locationList,
		refreshImageLocation,
		bookingDestroy,
		toggleConfirmBookingDestroy,
		modalMessageBooking,
		deleteBooking,
		limits,
		locationModalOpened,
		newLocationInfo,
		toggleModalLocation,
		formLocationValid,
		modalLocationType,
		handleLocationFormChange,
		handlePaginationLocation,
		locationCount,
		formSectorList,
		pageLocation,
		submitNewLocation,
		refresh,
		refreshData,
		notifColor,
		notifMessage,
		openNotif,
		setOpenNotif
	} = LocationListLogic();
	
	useEffect(() => {
		refreshData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.refresh]);
	
	return (
		<>
		<Snackbar
				place="tr"
				message={notifMessage}
				open={openNotif}
				close
				icon={
						notifColor === "success"
								? CheckCircleIcon
								: notifColor === "danger"
										? WarningIcon
										: NotificationsIcon
				}
				closeNotification={() => setOpenNotif(false)}
				color={notifColor}
		/>
		{bookingDestroy && (
				<Modal
						BackdropComponent={Backdrop}
						BackdropProps={{timeout: 500}}
						className={classes.modal}
						onClose={() => toggleConfirmBookingDestroy(null)}
						open={!!bookingDestroy}
				>
						<div className={classes.paper}>
								<ConfirmModal
										closeModal={() => toggleConfirmBookingDestroy(null)}
										kind={modalMessageBooking()}
										makeAction={() => deleteBooking(bookingDestroy.uuid)}
								/>
						</div>
				</Modal>
		)}
		{locationModalOpened && newLocationInfo && (
				<Modal
						BackdropComponent={Backdrop}
						BackdropProps={{
								timeout: 500,
						}}
						className={classes.modal}
						onClose={() => toggleModalLocation()}
						open={locationModalOpened}
				>
						<div
								className={classes.paper}
								style={{
										maxHeight: "80%",
								}}
						>
								<FormLocations
										formLocationValid={formLocationValid}
										modalLocationType={modalLocationType}
										newLocationInfo={newLocationInfo}
										refreshImageLocation={refreshImageLocation}
										handleLocationFormChange={handleLocationFormChange}
										submitNewLocation={submitNewLocation}
										toggleModalLocation={toggleModalLocation}
										formSectorList={formSectorList}
								/>
						</div>
				</Modal>
		)}
		<GridItem xs={12} sm={12} md={12}>
				<CardBody>
						<GridContainer>
								<GridItem xs={12} sm={12} md={12}>
										<MUIButton
												className={classes.button}
												onClick={() => toggleModalLocation()}
												startIcon={<AddIcon/>}
												style={{width: "auto"}}
										>
												Ajouter un emplacement
										</MUIButton>
								</GridItem>
						</GridContainer>
				</CardBody>
				<GridItem xs={12} sm={12} md={12}>
						{locationList && locationList.length === 0 && (
								<h4>Aucun emplacement à afficher</h4>
						)}
						{locationList && locationList.length > 0 && (
								<Table
										tableHeaderColor="primary"
										tableType="booking"
										tableHead={[
												"Titre",
												"Image",
												"Détails de l'emplacement",
												"Bâtiment",
												"",
										]}
										tableData={
												locationList &&
												locationList.map(location => {
														const locationList = {
																title: location?.title,
																img:
																		refreshImageLocation &&
																		(refreshImageLocation[location?.uuid] ? (
																				<CircularProgress/>
																		) : location?.has_picture ? (
																				<img
																						style={{
																								marginTop: "1%",
																								height: "100px",
																						}}
																						src={`${process.env.REACT_APP_CDN_URL}/app/module/reservation/locations/${location?.uuid}/image?refresh=${refresh}`}
																						alt=""
																						onError={i => {
																								i.target.style.display = "none";
																								i.target.alt =
																										"Aucune image disponible";
																						}}
																				/>
																		) : null),
																details: location?.details,
																sector: location?.sector?.name ?? "---",
																actions: (
																		<div
																				style={{
																						display: "flex-end",
																				}}
																		>
																				<Button
																						onClick={() =>
																								toggleModalLocation(location)
																						}
																						size="sm"
																						color="warning"
																				>
																						Éditer
																				</Button>
																				<Button
																						style={{marginLeft: "2%"}}
																						size="sm"
																						onClick={() =>
																								toggleConfirmBookingDestroy(
																										"location",
																										location
																								)
																						}
																						color="danger"
																				>
																						Supprimer
																				</Button>
																		</div>
																),
														};
														return Object.values(locationList);
												})
										}
								/>
						)}
						{locationCount > 0 && (
								<div
										style={{
												display: "flex",
												justifyContent: "center",
												paddingTop: 100,
										}}
								>
										<Pagination
												count={Math.ceil(locationCount / limits.location)}
												page={pageLocation}
												onChange={(event, pageNumber) => {
														handlePaginationLocation(pageNumber);
												}}
												classes={{ul: paginationClasses.ul}}
												size="large"
										/>
								</div>
						)}
				</GridItem>
		</GridItem>
		</>
	)
}