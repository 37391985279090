import { get_axs, post_axs, put_axs, remove_axs } from "helpers/fetcher";

export const reservation2nServices = {
  add,
  update,
  destroy,
  getAll,
  setLocation
};

async function add(data) {
  return post_axs(
    "/modules/reservations/2ns",
    new URLSearchParams({
      ...data,
    })
  );
}

async function update(uuid, data) {
  return put_axs(
    `/modules/reservations/2ns/${uuid}`,
    new URLSearchParams({
      ...data,
    })
  );
}

async function setLocation(uuid, locationUUID) {
    return put_axs(`/modules/reservations/2ns/${uuid}/location/${locationUUID}`);
  }

async function getAll() {
  return get_axs("/modules/reservations/2ns");
}


async function destroy(uuid) {
  return remove_axs(`/modules/reservations/2ns/${uuid}`);
}
