import {useEffect, useState} from "react";
import moment from "moment";
import localization from "moment/locale/fr";
import {
		reservationA1001Services,
		reservationCleaningServices
} from "services";

moment.updateLocale("fr", localization);

const A1001ListLogic = () => {
		const [refresh, setRefresh] = useState(false);

		const limits = {a1001: 15};

		const [openNotif, setOpenNotif] = useState(false);
		const [notifMessage, setNotifMessage] = useState("");
		const [notifColor, setNotifColor] = useState("info");

		const [newCleaningInfo, setNewCleaningInfo] = useState(undefined);
		const [formCleaningValid, setFormCleaningValid] = useState(false);
		const [isKeyRegenerate, setIsKeyRegenerate] = useState(false);

		const [a1001List, setA1001List] = useState(undefined);
		const [a1001Count, setA1001Count] = useState(0);
		const [pageA1001, setPageA1001] = useState(1);
		const [a1001ModalOpened, setA1001ModalOpened] = useState(false);
		const [newA1001Info, setNewA1001Info] = useState(undefined);
		const [modalA1001Type, setModalA1001Type] = useState(false);
		const [formA1001Valid, setFormA1001Valid] = useState(false);
		const [bookingDestroy, setBookingDestroy] = useState(undefined);

		const toggleConfirmBookingDestroy = (type, booking) => {
				setBookingDestroy(booking);
		};

		const deleteBooking = uuid => {
				deleteA1001(uuid);
				toggleConfirmBookingDestroy(null);
		};

		const modalMessageBooking = () => {
			return `Supprimer l'AXIS A1001 "${bookingDestroy.title}" ?`;
		};

		const refreshData = () => setRefresh(!refresh);

		useEffect(() => {
				(async () => {
					let a1001 = await reservationA1001Services.getAll();
					if (a1001) {
							setA1001Count(a1001.length);
							setA1001List(
									a1001.slice(
											limits.a1001 * (pageA1001 - 1),
											limits.a1001 * (pageA1001 - 1) + limits.a1001
									)
							);
					}
				})();
				// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [
				pageA1001,
				refresh,
		]);

		const handlePaginationA1001 = page => {
				setPageA1001(page);
		};

		const openNotification = (msg, color = "info", time = 6) => {
				setOpenNotif(false);
				setNotifMessage(msg);
				setNotifColor(color);
				setTimeout(() => setOpenNotif(false), time * 1000);
				setTimeout(() => setOpenNotif(true), 100);
		};
	
		const requestNewIcsKey = async uuid => {
				const res = await reservationCleaningServices.renewIcsKey(uuid);
				if (res) {
						const cleaningInfo = {...newCleaningInfo};
						cleaningInfo.ics_key = res?.ics_key;
						cleaningInfo.dialog = !cleaningInfo.dialog;
						setNewCleaningInfo(cleaningInfo);
						setIsKeyRegenerate(true);
						isFormCleaningValid(cleaningInfo);
				}
		};

		const isFormCleaningValid = cleaning => {
				if (isKeyRegenerate) {
						setFormCleaningValid(true);
						return;
				}
				if (!cleaning?.title?.length || !cleaning?.email?.length)
						setFormCleaningValid(false);
				else setFormCleaningValid(true);
		};

		const toggleModalA1001 = (a1001 = null) => {
				setFormA1001Valid(false);
				setModalA1001Type(a1001 ? true : false);
				const a1001Info = {
						uuid: a1001?.uuid,
						title: a1001?.title ? a1001?.title : "",
						host: a1001?.host ? a1001?.host : "",
						port: a1001?.port ? a1001?.port : "",
						is_weak_cert: typeof a1001?.is_weak_cert === "boolean" ? a1001?.is_weak_cert : false,
						username: a1001?.username ? a1001?.username : "",
						password: a1001?.password ? a1001?.password : "",
						showPassword: false,
				};
				setNewA1001Info(a1001Info);
				setA1001ModalOpened(!a1001ModalOpened);
		};

		const submitNewA1001 = async event => {
				event.preventDefault();
				const a1001Info = {
						title: newA1001Info.title,
						host: newA1001Info.host,
						port: newA1001Info.port,
						is_weak_cert: newA1001Info.is_weak_cert,
						username: newA1001Info.username,
						password: newA1001Info.password,
				};
				if (newA1001Info.uuid) {
						if (await reservationA1001Services.update(newA1001Info.uuid, a1001Info)) {
								openNotification(
										`L'AXIS A1001 ${newA1001Info?.title} a été modifié`,
										"success"
								);
						} else {
								openNotification(
										`Erreur, une erreur est survenu lors de la modification de l'AXIS A1001 ${newA1001Info?.title}`,
										"danger"
								);
						}
				} else {
						if (await reservationA1001Services.add(a1001Info)) {
								openNotification(
										`L'AXIS A1001 ${newA1001Info?.title} a été ajouté`,
										"success"
								);
						} else {
								openNotification(
										`Erreur, une erreur est survenu lors de l'ajout de l'AXIS A1001 ${newA1001Info?.title}`,
										"danger"
								);
						}
				}
				refreshData();
				setA1001ModalOpened(!a1001ModalOpened);
				setFormA1001Valid(true);
		};

		const handleA1001FormChange = (key, value, idx = 0) => {
				const a1001Info = {...newA1001Info};
				if (key === "showPassword") {
						a1001Info[key] = !a1001Info[key];
				} else {
						a1001Info[key] = value;
				}
				setNewA1001Info(a1001Info);
				isFormA1001Valid(a1001Info);
		};

		const isFormA1001Valid = a1001 => {
				if (
						!a1001?.title?.length ||
						!a1001?.host?.length ||
						!a1001?.username?.length ||
						!a1001?.password?.length ||
						isNaN(parseInt(a1001?.port)) ||
						parseInt(a1001?.port) < 0
				)
						setFormA1001Valid(false);
				else setFormA1001Valid(true);
		};

		const deleteA1001 = async uuid => {
				await reservationA1001Services.destroy(uuid);
				openNotification(
						`L'AXIS A1001 ${a1001List.find(it => it.uuid === uuid)?.title} a été supprimé`,
						"success"
				);
				refreshData();
		};

		return {
				a1001List,
				a1001Count,
				a1001ModalOpened,
				bookingDestroy,
				formA1001Valid,
				formCleaningValid,
				limits,
				modalA1001Type,
				newA1001Info,
				newCleaningInfo,
				notifColor,
				notifMessage,
				openNotif,
				pageA1001,
				refresh,
				deleteBooking,
				handleA1001FormChange,
				handlePaginationA1001,
				modalMessageBooking,
				refreshData,
				requestNewIcsKey,
				setOpenNotif,
				submitNewA1001,
				toggleConfirmBookingDestroy,
				toggleModalA1001
		};
};

export default A1001ListLogic;
