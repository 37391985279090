import axios from "axios";
import { logout } from "services/common/user.services";
import { authHeader, getOubaboxToken } from "helpers/auth-header";
import { isLogin } from "./";

// TODO: refacto this shit 💩 in only one method
// "One method to rule them all, one method to find them, One method to bring them all, and in the prod' bind them; In the Land of staging where the bugs lie."
// PS: we need to refacto all API first in order to have consistent header (quite uggly if we miss this step)

const fetcher_axs = method => async (url, payload, type, resType) => {
  try {
    const result = await axios({
      method,
      url: `${process.env.REACT_APP_API_URL}${url}`,
      data: payload,
      headers: {
        "Content-Type": type || "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": process.env.REACT_APP_BASE_URL,
        ...authHeader(),
      },
      responseType: resType || "",
    });
    if (result.status === 204) return true;
    // Handle case were response.data exist and it's due to an error
    return result.data;
  } catch (error) {
    if (
      error.response.status === 500 ||
      (error.response.status === 401 && isLogin())
    ) {
      logout();
    }
    return false;
  }
};

const fetcher_cdn = method => async (url, payload) => {
  try {
    const result = await axios({
      method,
      url: `${process.env.REACT_APP_CDN_URL}${url}`,
      data: payload,
    });
    if (result.status === 204) return true;
    // Handle case were response.data exist and it's due to an error
    return result.data;
  } catch (error) {
    if (error?.response?.status === 500 || error?.response?.status === 401) {
      logout();
    }
    return false;
  }
};

const fetcher_obx = method => async (url, payload, image = false) => {
  try {
    const result = await axios({
      method,
      url: `${process.env.REACT_APP_API_OUBABOX_URL}${url}`,
      data: payload,
      responseType: image ? "blob" : "json",
      headers: {
        "Content-Type": `application/${image ? "octet-stream" : "json"}`,
        "Content-Disposition": image && `attachment; filename="screenshot.png"`,
        ...getOubaboxToken(),
      },
    });
    return result.status === 204 ? true : result.data;
  } catch (error) {
    if (error.response.status === 500 || error.response.status === 401)
      logout();
  }
};

export const get_axs = fetcher_axs("GET");
export const post_axs = fetcher_axs("POST");
export const put_axs = fetcher_axs("PUT");
export const remove_axs = fetcher_axs("DELETE");

export const get_cdn = fetcher_cdn("GET");
export const post_cdn = fetcher_cdn("POST");
export const put_cdn = fetcher_cdn("PUT");
export const remove_cdn = fetcher_cdn("DELETE");

export const get_obx = fetcher_obx("GET");
export const post_obx = fetcher_obx("POST");
export const put_obx = fetcher_obx("PUT");
export const remove_obx = fetcher_obx("DELETE");
