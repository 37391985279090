import { get_axs, put_axs, post_axs, remove_axs } from "helpers/fetcher";

export const privilegeService = {
  getAll,
  update,
  addAdmin,
  destroy,
  getSelf,
};

async function getAll() {
  const response = get_axs("/privileges");
  return response;
}

async function getSelf() {
  const response = get_axs(`/privileges/self`);
  return response;
}

async function addAdmin(data) {
  const response = post_axs(
    "/privileges",
    new URLSearchParams({
      ...data,
    })
  );
  return response;
}

async function update(uid, data) {
  const response = put_axs(
    `/privileges/${uid}`,
    new URLSearchParams({
      ...data,
    })
  );
  return response;
}

async function destroy(id) {
  const response = remove_axs(
    `/privileges/${id}`,
  );
  return response;
}
