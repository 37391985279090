import moment                  from "moment";
import localization            from "moment/locale/fr";
import { useEffect, useState } from "react";
import { saveAs              } from "file-saver";
import {
  userService,
  sectorServices,
  statisticServices,
  fetchOubaboxes,
  fetchEstates,
  fetchStatistics
} from "services";
import { loadState } from "helpers/localStorage";

moment.updateLocale("fr", localization);

const StatisticsLogic = (mode) => {
  const [startDate,      setStartDate     ] = useState(moment({ d: 1, h: 0, m: 0, s: 0 }));
  const [endDate,        setEndDate       ] = useState(moment({ h: 23, m: 59, s: 59 }));
  const [oubaboxsList,   setOubaboxsList  ] = useState();
  const [sizesList,      setSizesList     ] = useState();
  const [deliveriesList, setDeliveriesList] = useState();
  const [estatesToUsers, setEstatesToUsers] = useState();
  const [sectorsList,    setSectorsList   ] = useState([]);
  const [estatesList,    setEstatesList   ] = useState([]);
  const [estateFilter,   setEstateFilter  ] = useState([]);
  const [oubaboxFilter,  setOubaboxFilter ] = useState([]);
  const [sectorFilter,   setSectorFilter  ] = useState([]);
  const [globalStats,    setGlobalStats   ] = useState(false);
  const [isModalOpen,    setIsModalOpen   ] = useState(false);
  const [grouped,        setGrouped       ] = useState(false);
  const [dropped,        setDropped       ] = useState(true);
  const [pickedup,       setPickedUp      ] = useState(true);
  const [interval,       setIntervalValue ] = useState(2);
  const [data,           setData          ] = useState();
  const [type,           setType          ] = useState(0);
  const [myself,         setMyself        ] = useState();
  const [refresh                          ] = useState(false);

  useEffect(() => {
    (async () => {
      const sectors  = await sectorServices.getAll();
      const estates  = await fetchEstates();
      const oubaboxs = (await fetchOubaboxes(loadState("site")?.uuid))?.filter(o => !mode ? o.estateId === loadState("site")?.id : o);
      setMyself       (await userService.getSelf());
      setEstateFilter (estates?.map(estate => estate.uuid)?.filter(e => e === loadState("site").uuid ));
      setOubaboxFilter(oubaboxs?.map(oubabox => oubabox.uuid) || []);
      setSectorFilter (sectors?.map(sector => sector.uuid)    || []);
      setEstatesList  (estates);
      setOubaboxsList (oubaboxs);
      setSectorsList  (sectors);
      fetchData();
    })();
  }, []);

  useEffect(() => {
    (async () => fetchData())();
  }, [interval, startDate, endDate, oubaboxFilter, estateFilter, sectorFilter]);

  useEffect(() => {
    setStartDate(moment({ d: 1, h: 0, m: 0, s: 0 }));
    setEndDate(moment({ h: 23, m: 59, s: 59 }));
    setOubaboxFilter(oubaboxsList?.map(oubabox => oubabox.uuid) || []);
    setEstateFilter([ loadState("site").uuid ]);
    setSectorFilter(sectorsList?.map(sector => sector.uuid)     || []);
    setIntervalValue(2);
    setGrouped(false);
    setType(0);
  }, [globalStats, refresh]);

  const fetchData = async () => {
    const {
      deliveries, estatesToUsers, users, averageTime,
      deliveryByDay, pickupByDay, graphData, aquisition, sizes
    } = await fetchStatistics([
      `start=${startDate?.format("MM/DD/YYYY")}`,
      `end=${endDate?.format("MM/DD/YYYY")}`,
      `interval=${interval}`,
      estateFilter?.length  && `estates=${estateFilter}`,
      sectorFilter?.length  && `sectors=${sectorFilter}`,
      oubaboxFilter?.length && `oubaboxs=${oubaboxFilter}`,
    ].filter(_ => _).join('&')) || {};
    sizes          && setSizesList(sizes);
    deliveries     && setDeliveriesList(deliveries);
    estatesToUsers && setEstatesToUsers(estatesToUsers);
    setData({ graphData, aquisition, users, averageTime, deliveryByDay, pickupByDay });
  };

  const toggleResetFilters = () => setGlobalStats(!globalStats);

  const handleFilter = (type, item) => {
    type === "oubabox"  && setOubaboxFilter(item);
    type === "estate"   && setEstateFilter (item);
    type === "sector"   && setSectorFilter (item);
    type === "interval" && setIntervalValue(item);
    type === "grouped"  && setGrouped(item);
    type === "dropped"  && setDropped(item);
    type === "pickup"   && setPickedUp(item);
    type === "type"     && setType(item);
  };

  const downloadStatistics = async () => {
    const response = await statisticServices.downloadStatistics({
      start: startDate?.format("MM/DD/YYYY"),
      end: endDate?.format("MM/DD/YYYY"),
      oubaboxs: oubaboxFilter,
      sectors: sectorFilter,
    });
    const blob = new Blob([response], {
      type: "application/vnd.ms-excel;charset=utf-8",
    });
    saveAs(
      blob,
      `Statistiques-${startDate?.format("MM/DD/YYYY")}-${endDate?.format(
        "MM/DD/YYYY"
      )}.xlsx`
    );
    setIsModalOpen(false);
  };

  return {
    oubaboxFilter,
    sectorFilter,
    estatesToUsers,
    handleFilter,
    refresh,
    setStartDate,
    setEndDate,
    startDate,
    sectorsList,
    downloadStatistics,
    toggleResetFilters,
    estatesList,
    estateFilter,
    sizesList,
    endDate,
    isModalOpen,
    oubaboxsList,
    deliveriesList,
    interval,
    myself,
    data,
    type,
    grouped,
    dropped,
    pickedup
  };
};

export default StatisticsLogic;
