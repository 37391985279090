import {
  get_obx as get,
  post_obx as post,
  put_obx as put,
  remove_obx as remove,
} from "helpers/fetcher";

// Auth
export const loginUser = payload => post(`/auth/signin`, payload);

// Oubabox Containers/Doors
export const updateDoor = (uuid, payload) => put(`/boxes/${uuid}`, payload);
export const removeDoor = uuid => remove(`/boxes/${uuid}`);
export const fetchSizes = () => get(`/boxes/sizes`);

// Estate/Site
export const fetchEstates = () => get(`/estates`);
export const fetchEstateSectors = estateUUID =>
  get(`/estates/${estateUUID}/sectors`);

// Logs
export const fetchLogs = query => get(`/logs?${query}`);
export const updateLog = (uuid, payload) => put(`/logs/${uuid}`, payload);
export const bulkUpdateLog = payload => put(`/logs/bulk`, payload);

// Oubabox
export const getOubabox = uuid => get(`/oubaboxs/${uuid}`);
export const removeOubabox = uuid => remove(`/oubaboxs/${uuid}`);
export const fetchOubaboxes = uuid => get(`/oubaboxs?uuid=${uuid}`);
export const createOubabox = payload => post(`/oubaboxs`, payload);
export const updateOubabox = (uuid, payload) =>
  put(`/oubaboxs/${uuid}`, payload);
export const fetchDoors = uuid => get(`/oubaboxs/${uuid}/boxes`);
export const createDoor = (uuid, payload) =>
  post(`/oubaboxs/${uuid}/boxes`, payload);
export const fetchOubaboxSectors = uuid => get(`/oubaboxs/${uuid}/sectors`);
export const attachOubaboxSectors = (uuid, sectorUUID) =>
  post(`/oubaboxs/${uuid}/sectors/${sectorUUID}`);
export const detachOubaboxSectors = (uuid, sectorUUID) =>
  remove(`/oubaboxs/${uuid}/sectors/${sectorUUID}`);

// Oubabox Actions
export const getScreenshot = (uuid, log) =>
  get(`/oubaboxs/${uuid}/action/screenshot/${log}`, undefined, true);
export const getCamShot = (uuid, log) =>
  get(`/oubaboxs/${uuid}/action/camshot/${log}`, undefined, true);
export const makeScreenshot = uuid =>
  put(`/oubaboxs/${uuid}/action/screenshot`, undefined, true);
export const makeReload = uuid => put(`/oubaboxs/${uuid}/action/reload`);
export const makeReboot = uuid => put(`/oubaboxs/${uuid}/action/reboot`);
export const makeOpen = (oUUID, bUUID) =>
  put(`/oubaboxs/${oUUID}/action/open/${bUUID}`);
export const makeEnable = (oUUID, bUUID) =>
  put(`/oubaboxs/${oUUID}/action/enable/${bUUID}`);
export const makeFree = (oUUID, bUUID) =>
  put(`/oubaboxs/${oUUID}/action/free/${bUUID}`);

// Statistics
export const fetchStatistics = payload => get(`/statistics?${payload}`);

export const oubaboxServices = {
  loginUser,
  updateDoor,
  removeDoor,
  fetchSizes,
  fetchEstates,
  fetchEstateSectors,
  getOubabox,
  removeOubabox,
  fetchOubaboxes,
  createOubabox,
  updateOubabox,
  fetchDoors,
  fetchOubaboxSectors,
  attachOubaboxSectors,
  detachOubaboxSectors,
  createDoor,
  getScreenshot,
  getCamShot,
  makeScreenshot,
  makeReload,
  makeReboot,
  makeOpen,
};

export default oubaboxServices;
