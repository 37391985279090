/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { render } from "react-dom";
import { history, authHeader } from "helpers";
import { Router, Redirect, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/moment"; // choose your lib

import { store } from "helpers";
import PrivateRoute from "components/PrivateRoutes/PrivatesRoutes";
import PublicRoute from "components/PublicRoutes/PublicRoutes";

// core components
import Auth from "layouts/Auth.js";
import Admin from "layouts/Admin.js";
import "./index.css";
import "assets/css/material-dashboard-react.css?v=1.8.0";

render(
  <Provider store={store}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
        <Switch>
          <PublicRoute restricted={true} path="/login" component={Auth} />
          <PrivateRoute path="/admin" component={Admin} />
          {/* <PublicRoute path="/admin/panel" component={AdminPanel} /> */}
          {authHeader() ? (
            <Redirect from="/" to="/admin" />
          ) : (
            <Redirect from="/" to="/login" />
          )}
        </Switch>
      </Router>
    </MuiPickersUtilsProvider>
  </Provider>,

  document.getElementById("root")
);
