/* eslint-disable react/no-unescaped-entities */
import React /*useEffect*/ from "react";
import PropTypes from "prop-types";

import {
  CssBaseline,
  Container,
  TextField,
  Grid,
  FormControlLabel,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  makeStyles,
  Checkbox,
  Typography,
} from "@material-ui/core";

import Switch from "@material-ui/core/Switch";

import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-react/views/eventStyle.js";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function FormCleaning(props) {
  const classes = useStyles();

  const {
    formCleaningValid,
    modalCleaningType,
    newCleaningInfo,
    handleCleaningFormChange,
    requestNewIcsKey,
    submitNewCleaning,
    toggleModalCleaning,
  } = props;
  return (
    <Container
      component="main"
      maxWidth="md"
      style={{
        overflowY: "auto",
      }}
    >
      <CssBaseline />
      <Dialog
        open={newCleaningInfo.dialog}
        onClose={() => handleCleaningFormChange("dialog", null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Attention"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span role="img" aria-label="warning">
              ⚠️
            </span>
            {' '}En régénérant la clé, l'URL du calendrier ICS va être invalidée
            et remplacée. Veuillez confirmer l'opération.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCleaningFormChange("dialog", null)}
            color="info"
          >
            Annuler
          </Button>
          <Button
            onClick={() => requestNewIcsKey(newCleaningInfo.uuid)}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <div>
        <div className={classes.headerModal}>
          {modalCleaningType ? (
            <Typography variant="h5">Modifier la société de ménage</Typography>
          ) : (
            <Typography variant="h5">Ajouter une société de ménage</Typography>
          )}
          <IconButton
            size="small"
            aria-label="delete"
            onClick={() => toggleModalCleaning()}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <form className={classes.form} onSubmit={submitNewCleaning} noValidate>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <InputLabel>Société de ménage *</InputLabel>
              <TextField
                autoComplete="fname"
                defaultValue={newCleaningInfo.title}
                name="title"
                required
                fullWidth
                id="title"
                onChange={event =>
                  handleCleaningFormChange("title", event.target.value)
                }
                autoFocus
              />
            </Grid>
            <Grid item md={6}>
              <InputLabel>Prénom du responsable</InputLabel>
              <TextField
                id="managerFirstName"
                value={newCleaningInfo.managerFirstName}
                onChange={e =>
                  handleCleaningFormChange("managerFirstName", e.target.value)
                }
                required
                fullWidth
              />
            </Grid>
            <Grid item md={6}>
              <InputLabel>Nom du responsable</InputLabel>
              <TextField
                id="managerLastName"
                value={newCleaningInfo.managerLastName}
                onChange={e =>
                  handleCleaningFormChange("managerLastName", e.target.value)
                }
                required
                fullWidth
              />
            </Grid>
            <Grid item md={5}>
              <InputLabel>Téléphone fixe</InputLabel>
              <TextField
                id="landlinePhoneNumber"
                value={newCleaningInfo.landlinePhoneNumber}
                onChange={e =>
                  handleCleaningFormChange("landlinePhoneNumber", e.target.value)
                }
                required
                fullWidth
              />
            </Grid>
            <Grid item md={5}>
              <InputLabel>Téléphone mobile</InputLabel>
              <TextField
                id="mobilePhoneNumber"
                value={newCleaningInfo.mobilePhoneNumber}
                onChange={e =>
                  handleCleaningFormChange("mobilePhoneNumber", e.target.value)
                }
                required
                fullWidth
              />
            </Grid>
            <Grid md={2}>
              <FormControlLabel
                value="sendSMSNotification"
                control={
                  <Switch
                    checked={newCleaningInfo.sendSMSNotification}
                    onChange={event =>
                      handleCleaningFormChange("sendSMSNotification", event.target.checked)
                    }
                    color="primary"
                    value={newCleaningInfo.sendSMSNotification}
                  />
                }
                label="Notifier par SMS"
                labelPlacement="top"
              />
            </Grid>
            <Grid item md={12}>
              <InputLabel>SIREN</InputLabel>
              <TextField
                id="SIREN"
                value={newCleaningInfo.SIREN}
                onChange={e =>
                  handleCleaningFormChange("SIREN", e.target.value)
                }
                required
                fullWidth
              />
            </Grid>
            <Grid item md={12}>
              <InputLabel>Adresse (N° et Nom)</InputLabel>
              <TextField
                id="addressStreet"
                value={newCleaningInfo.addressStreet}
                onChange={e =>
                  handleCleaningFormChange("addressStreet", e.target.value)
                }
                required
                fullWidth
              />
            </Grid>
            <Grid item md={9}>
              <InputLabel>Ville</InputLabel>
              <TextField
                id="addressCity"
                value={newCleaningInfo.addressCity}
                onChange={e =>
                  handleCleaningFormChange("addressCity", e.target.value)
                }
                required
                fullWidth
              />
            </Grid>
            <Grid item md={3}>
              <InputLabel>Code postal</InputLabel>
              <TextField
                id="addressZipcode"
                value={newCleaningInfo.addressZipcode}
                onChange={e =>
                  handleCleaningFormChange("addressZipcode", e.target.value)
                }
                required
                fullWidth
              />
            </Grid>
            <Grid item md={12}>
              <InputLabel>
                Email, Séparation des adresses par une virgule (,) *
              </InputLabel>
              <TextField
                id="email"
                value={newCleaningInfo.email}
                onChange={e =>
                  handleCleaningFormChange("email", e.target.value)
                }
                required
                fullWidth
              />
            </Grid>
            <Grid item md={12}>
              <InputLabel>
                Email CC, Séparation des adresses par une virgule (,)
              </InputLabel>
              <TextField
                id="emailCc"
                value={newCleaningInfo.email_cc}
                onChange={e =>
                  handleCleaningFormChange("email_cc", e.target.value)
                }
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <InputLabel>Jours travaillés</InputLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newCleaningInfo.working_days[0] === "1"}
                    onChange={e =>
                      handleCleaningFormChange(
                        "recurrence",
                        e.target.checked,
                        0
                      )
                    }
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                label="Lundi"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newCleaningInfo.working_days[1] === "1"}
                    onChange={e =>
                      handleCleaningFormChange(
                        "recurrence",
                        e.target.checked,
                        1
                      )
                    }
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                label="Mardi"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newCleaningInfo.working_days[2] === "1"}
                    onChange={e =>
                      handleCleaningFormChange(
                        "recurrence",
                        e.target.checked,
                        2
                      )
                    }
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                label="Mercredi"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newCleaningInfo.working_days[3] === "1"}
                    onChange={e =>
                      handleCleaningFormChange(
                        "recurrence",
                        e.target.checked,
                        3
                      )
                    }
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                label="Jeudi"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newCleaningInfo.working_days[4] === "1"}
                    onChange={e =>
                      handleCleaningFormChange(
                        "recurrence",
                        e.target.checked,
                        4
                      )
                    }
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                label="Vendredi"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newCleaningInfo.working_days[5] === "1"}
                    onChange={e =>
                      handleCleaningFormChange(
                        "recurrence",
                        e.target.checked,
                        5
                      )
                    }
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                label="Samedi"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newCleaningInfo.working_days[6] === "1"}
                    onChange={e =>
                      handleCleaningFormChange(
                        "recurrence",
                        e.target.checked,
                        6
                      )
                    }
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                label="Dimanche"
              />
            </Grid>
            {modalCleaningType &&
              newCleaningInfo.ics_key &&
              newCleaningInfo.uuid?.length && (
                <Grid item md={12}>
                  <InputLabel>Clé ICS</InputLabel>
                  <div style={{ display: "flex" }}>
                    <Grid item md={5}>
                      <TextField
                        id="ics"
                        value={newCleaningInfo.ics_key}
                        fullWidth
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    </Grid>
                    <Button
                      style={{ marginLeft: "3%" }}
                      type="button"
                      variant="contained"
                      color="primary"
                      onClick={() => handleCleaningFormChange("dialog", null)}
                    // onClick={() => requestNewIcsKey(newCleaningInfo.uuid)}
                    >
                      Régénérer la clé
                    </Button>
                  </div>
                </Grid>
              )}
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!formCleaningValid}
          >
            Enregister
          </Button>
        </form>
      </div>
    </Container>
  );
}
FormCleaning.propTypes = {
  formCleaningValid: PropTypes.bool,
  modalCleaningType: PropTypes.bool,
  newCleaningInfo: PropTypes.object,
  handleCleaningFormChange: PropTypes.func,
  requestNewIcsKey: PropTypes.func,
  submitNewCleaning: PropTypes.func,
  toggleModalCleaning: PropTypes.func,
};
