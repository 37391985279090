import React, { useEffect } from "react";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import { Button as MUIButton, Divider, Modal, Backdrop } from "@material-ui/core";
import { ReactComponent as AddIcon } from "assets/img/svg/AddIcon.svg";
import CardHeader from "components/Card/CardHeader.js";
import ReactMarkdown from "react-markdown";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardFooter from "components/Card/CardFooter.js";
import BookingRulesLogic from "./../TabLogics/BookingRulesLogic";
import Pagination from "@material-ui/lab/Pagination";
import gfm from "remark-gfm";
import moment from "moment";
import localization from "moment/locale/fr";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/eventStyle.js";
import FormRules from "components/FormUsers/FormRules.js";
import ConfirmModal from "components/ConfirmModal";
import Snackbar from "components/Snackbar/Snackbar.js";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
import NotificationsIcon from "@material-ui/icons/Notifications";

moment.updateLocale("fr", localization);

const paginationStyles = makeStyles(() => ({
	ul: {
		"& .MuiPaginationItem-root": {
			color: "#6E6FA9",
		},
		"& .Mui-selected": {
			backgroundColor: "#6E6FA9",
			color: "#E7E6ED",
		},
	},
}));

export default function BookingRulesView(props){
	
	const useStyles = makeStyles(styles);
	const classes = useStyles();
	const paginationClasses = paginationStyles();
	
	const {
		myself,
		rules,
		toggleModalRule,
		rulesCount,
		toggleConfirmItemDestroy,
		limits,
		pageRule,
		handlePaginationRule,
		ruleModalOpened,
		newRuleInfo,
		formRuleValid,
		modalRuleType,
		pageModalRule,
		handleRuleFormChange,
		handlePaginationModalRule,
		submitNewRule,
		itemDestroy,
		deleteRule,
		refreshData,
		notifMessage,
		openNotif,
		setOpenNotif,
		notifColor
	} = BookingRulesLogic();
	
	useEffect(() => {
		refreshData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.refresh]);
	
	return (
		<>
			<Snackbar
				place="tr"
				message={notifMessage}
				open={openNotif}
				close
				icon={notifColor === "success" ? CheckCircleIcon : (notifColor === "danger" ? WarningIcon : NotificationsIcon)}
				closeNotification={() => setOpenNotif(false)}
				color={notifColor}
			/>
			{ruleModalOpened && newRuleInfo && (
				<Modal
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}
					className={classes.modal}
					onClose={() => toggleModalRule()}
					open={ruleModalOpened}
				>
					<div className={classes.paper} style={{
						width: "80%",
						maxHeight: "80%",
						maxWidth: "80%",
					}}>
						<FormRules
							formRuleValid={formRuleValid}
							newRuleInfo={newRuleInfo}
							modalRuleType={modalRuleType}
							pageModalRule={pageModalRule}
							handleRuleFormChange={handleRuleFormChange}
							handlePaginationModalRule={handlePaginationModalRule}
							submitNewRule={submitNewRule}
							toggleModalRule={toggleModalRule}
						/>
					</div>
				</Modal>
			)
			}
			{itemDestroy && (
				<Modal
					BackdropComponent={Backdrop}
					BackdropProps={{ timeout: 500 }}
					className={classes.modal}
					onClose={() => toggleConfirmItemDestroy(null)}
					open={itemDestroy ? true : false}
				>
					<div className={classes.paper}>
						<ConfirmModal
							closeModal={() => toggleConfirmItemDestroy(null)}
							kind={`Supprimer la règle "${itemDestroy.title}" ?`}
							makeAction={() => deleteRule(itemDestroy.uuid)}
						/>
					</div>
				</Modal>
			)}
			<GridItem xs={12} sm={12} md={12}>
				{(myself?.rights?.role_superadmin || myself?.rights?.role_bookings_rules_add) && (
					<CardBody>
						<GridContainer>
							<GridItem xs={12} sm={12} md={12}>
								<MUIButton
									className={classes.button}
									onClick={() => toggleModalRule()}
									startIcon={<AddIcon />}
									style={{ width: "auto" }}
								>
									Ajouter une règle
								</MUIButton>
							</GridItem>
						</GridContainer>
					</CardBody>)
				}
				<div>
					{!myself?.rights?.role_superadmin && !myself?.rights?.role_bookings_rules_read ? (
						<GridItem xs={12} sm={12} md={6}>
							<h4>
								{
									"Vous n'avez pas la permission de voir les règles"
								}
							</h4>
						</GridItem>
					) : (<div>
						{rules && rules.length === 0 && (
							<h4>
								Aucune règle à afficher
							</h4>
						)}
						{rules &&
							rules?.map((rule, i) => (
								<div key={i}>
									<Card>
										{rule?.ressources[0]?.title && (
											<div>
												<CardHeader>
													<div style={{ marginLeft: "1%", fontWeight: "bold", fontSize: 16 }}>
														{rule?.ressources[0]?.title}
													</div>
												</CardHeader>
												<Divider />
											</div>
										)
										}
										<CardBody>
											<GridItem xs={12} sm={12} md={12}>
												<h1 style={{ fontWeight: "bold" }}>
													{rule?.title}
												</h1>
												<Divider />
												{rule.content.split("\n").map((it, i) => (
													(it.length ? <ReactMarkdown key={i} remarkPlugins={[gfm]}
																											children={it.replaceAll("\t", "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;")} /> :
															<div key={i} style={{ whiteSpace: "pre-wrap" }}>{"\r\n"}</div>
													)),
												)}
											</GridItem>
											<GridItem xs={12} sm={12} md={12} style={{ marginTop: "1%" }}>
												{(myself?.rights?.role_superadmin || myself?.rights?.role_bookings_rules_edit) && (
													<Button
														onClick={() => toggleModalRule(rule)}
														color="warning"
													>Éditer</Button>)
												}
												{(myself?.rights?.role_superadmin || myself?.rights?.role_bookings_rules_delete) && (
													<Button
														style={{ marginLeft: "2%" }}
														onClick={() => toggleConfirmItemDestroy(rule)}
														color="danger"
													>Supprimer</Button>)
												}
											</GridItem>
										</CardBody>
										<Divider />
										<CardFooter>
											Crée le{" "}{moment(rule.created_at).format("DD/MM/YYYY à HH:mm")}
										</CardFooter>
									</Card>
								</div>
							))
						}
						{rulesCount > 0 && (
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									paddingTop: 100,
								}}
							>
								<Pagination
									count={Math.ceil(rulesCount / limits.rule)}
									page={pageRule}
									onChange={(event, pageNumber) => {
										handlePaginationRule(pageNumber);
									}}
									classes={{ ul: paginationClasses.ul }}
									size="large"
								/>
							</div>
						)}
					</div>)
					}
				</div>
			</GridItem>
		</>
	)
}