import { get_axs } from "helpers/fetcher";

export const statisticServices = {
  getAllStatistics,
  downloadStatistics,
  //getUsersGraph
};

async function getAllStatistics({ start, end, state, estates, sectors, created, activated }) {
  return get_axs([
    `/statistics/users?`,
    start && `start=${start}`,
    end && `end=${end}`,
    state && `state=${state}`,
    (estates && estates.length) && `estates=${estates}`,
    (sectors && sectors.length) && `sectors=${sectors}`,
    `created=${created ? true : false}`,
    `activated=${activated ? true : false}`,
  ].filter(_ => _).join('&'));
}

/*async function getUsersGraph({ start, end, state, created, activated }) {
  return get([
    `/users/graph?`,
    start && `start=${start}`,
    end && `end=${end}`,
    state && `state=${state}`,
    `created=${created ? true : false}`,
    `activated=${activated ? true : false}`,
  ].filter(_ => _).join('&'));
}*/

async function downloadStatistics({ start, end, ressources, estates }) {
  return get_axs([
    `/statistics?`,
    start && `start=${start}`,
    end && `end=${end}`,
    (estates && estates.length) && `estates=${estates}`,
    (ressources && ressources.length) && `ressources=${ressources}`
  ].filter(_ => _).join('&'), undefined, undefined, "arraybuffer");
}