import { useEffect, useState } from "react";
import { plannedClosureServices, reservationLocationServices  } from "services";
import moment from "moment";

const AccessRestrictionLogic = () => {

	const limit = 20

	const [refresh, setRefresh] = useState(false);

	const [openNotif, setOpenNotif] = useState(false);
	const [notifMessage, setNotifMessage] = useState("");
	const [notifColor, setNotifColor] = useState("info");
	const [modalType, setModalType] = useState(false);
	const [page, setPage] = useState(1)
	const [itemsList, setItemsList] = useState(undefined)
	const [itemsCount, setItemsCount] = useState(undefined)
	const [editModalOpened, setEditModalOpened] = useState(false);	
	const [newItemInfo, setNewItemInfo] = useState(undefined);
	const [locationList, setLocationList] = useState([]);
	const [itemDestroy, setItemDestroy] = useState(undefined);
	const [formValid, setFormValid] = useState(false);
	
	const toggleConfirmItemDestroy = item => setItemDestroy(item);


	useEffect(() => {
		(async () => {
			const plannedClosures = await plannedClosureServices.getAll();
			if (plannedClosures) {
				setItemsCount(plannedClosures.length)
				setItemsList(plannedClosures.slice(limit * (page - 1), limit * (page - 1) + limit))
			}
		})();
	}, [refresh, page]);

	const refreshData = () => setRefresh(!refresh);


	const deleteItem = async uuid => {
		await plannedClosureServices.destroy(uuid);
		openNotification(`Le planning a été supprimé`, 'success')
		toggleConfirmItemDestroy(null)
		refreshData()
	};
	
	const handlePagination = page => {
		setPage(page);
	};
	
	const fetchLocations = async () => {
			const location = await reservationLocationServices.getAll();
			if (location) {
					setLocationList(location);
			}
	};
	
	const toggleModal = async (item = null) => {
		await fetchLocations();
		setModalType(item ? true : false);
		const itemInfo = {
			uuid: item?.uuid,
			id: item?.id,
			note: item?.note,
			locationId: item?.location?.id,
			dateBegin: item?.dateBegin,
			dateEnd: item?.dateEnd,
		};
		setNewItemInfo(itemInfo);
		setEditModalOpened(!editModalOpened);
		checkDataValidity(itemInfo);
	};
	
	const openNotification = (msg, color = "info", time = 6) => {
		setOpenNotif(false);
		setNotifMessage(msg);
		setNotifColor(color);
		setTimeout(() => setOpenNotif(false), time * 1000);
		setTimeout(() => setOpenNotif(true), 100);
	};
	
	const checkDataValidity = (itemInfo) => {
		setFormValid(false);
		
		if (itemInfo?.dateBegin === null || typeof itemInfo?.dateBegin === 'undefined'){
			return;
		}
		
		if (itemInfo?.dateEnd === null || typeof itemInfo?.dateEnd === 'undefined'){
			return;
		}
		
		const mDateBegin = moment(itemInfo?.dateBegin ?? null);
		const mDateEnd = moment(itemInfo?.dateEnd ?? null);
		const validDatePeriod = mDateBegin.isBefore(mDateEnd)
		
		if (validDatePeriod === false){
			return;
		}
		
		const intLocationId = parseInt(itemInfo.locationId, 10);
		
		if (isNaN(intLocationId)){
			return
		}
		
		setFormValid(true);
	}
	
	const handleFormChange = (key, value) => {
		let itemInfo = { ...newItemInfo };
		itemInfo[key] = value;
		checkDataValidity(itemInfo);
		setNewItemInfo(itemInfo);
	};
	
	const submitItem = async event => {
		event.preventDefault();
		if (newItemInfo.uuid) {
			const updated = await plannedClosureServices.update(newItemInfo.uuid, {
				dateBegin: newItemInfo.dateBegin,
				dateEnd: newItemInfo.dateEnd,
				locationId: newItemInfo.locationId,
				note: newItemInfo.note ?? ''
			});
			if (updated) {
				openNotification(`Le planning a été modifié`, 'success')
			} else {
				openNotification(`Erreur, une erreur est survenu lors de la modification du planning`, 'danger')
			}
		} else {
			const added = await plannedClosureServices.add({
				dateBegin: newItemInfo.dateBegin,
				dateEnd: newItemInfo.dateEnd,
				locationId: newItemInfo.locationId,
				note: newItemInfo.note ?? ''
			});
			if (added) {
				openNotification(`Le planning été ajouté`, 'success')
			} else {
				openNotification(`Erreur, une erreur est survenu lors de l'ajout du planning`, 'danger')
			}
		}
		setEditModalOpened(!editModalOpened);
		refreshData();
	};

	return {
		itemsCount,
		itemsList,
		limit,
		newItemInfo,
		notifColor,
		notifMessage,
		openNotif,
		page,
		handlePagination,
		refreshData,
		setOpenNotif,
		handleFormChange,
		editModalOpened,
		toggleModal,
		modalType,
		submitItem,
		locationList,
		toggleConfirmItemDestroy,
		itemDestroy,
		formValid,
		deleteItem
	}
}

export default AccessRestrictionLogic