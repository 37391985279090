import React, { useEffect } from "react";
// core components
import Tabs from "components/CustomTabs/CustomTabs.js";
import GridContainer from "components/Grid/GridContainer.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AssignmentIcon from "@material-ui/icons/Assignment";
import WarningIcon from '@material-ui/icons/Warning';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import GroupListView from './TabViews/GroupListView'
import GroupImportView from './TabViews/GroupImportView'

export default function Groups(props) {

  return (
    <div>
      <GridContainer>
        <Tabs
          tabs={[
            {
              tabName: "Appartements",
              tabIcon: AssignmentIcon,
              tabContent: <GroupListView />
            },
            {
              tabName: "Importer des appartements",
              tabIcon: GroupAddIcon,
              tabContent: <GroupImportView />,
            }
          ]}
        />
      </GridContainer>
    </div>
  )
}