import React, { useEffect, useMemo } from "react";
import GroupImportLogic from "../TabLogics/GroupImportLogic";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import WarningIcon from '@material-ui/icons/Warning';
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table.js";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Snackbar from "components/Snackbar/Snackbar.js";
import NotificationsIcon from '@material-ui/icons/Notifications';

import {
	Tooltip,
	Box,
	CircularProgress,
	Typography
} from "@material-ui/core";

const baseStyle = {
	flex: 1,
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	padding: "20px",
	borderWidth: 2,
	borderRadius: 2,
	borderColor: "#eeeeee",
	borderStyle: "dashed",
	backgroundColor: "#fbfbfb",
	color: "#bdbdbd",
	outline: "none",
	transition: "border .24s ease-in-out",
};

const activeStyle = {
	borderColor: "#2196f3",
};

const acceptStyle = {
	borderColor: "#00e676",
};

const rejectStyle = {
	borderColor: "#ff1744",
};

export default function GroupImportView(props) {
	
	useEffect(() => {
		refreshData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.refresh]);
	
	const {
		getRootProps,
		isDragActive,
		isDragAccept,
		isDragReject,
		getInputProps,
		file,
		readFile,
		errorUpload,
		fileUpload,
		fileData,
		progress,
		fileTemplate,
		uploadGroup,
		errorFileData,
		setErrorFileData,
		uploadGroupStart,
		notifMessage,
		openNotif,
		notifColor,
		setOpenNotif,
		refreshData
	} = GroupImportLogic();
	
	const style = useMemo(
		() => ({
			...baseStyle,
			...(isDragActive ? activeStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {}),
		}),
		[isDragActive, isDragReject, isDragAccept]
	);

	return (
		<>
		<Snackbar
			place='tr'
			message={notifMessage}
			open={openNotif}
			close
			icon={notifColor === 'success' ? CheckCircleIcon : (notifColor === 'danger' ? WarningIcon : NotificationsIcon)}
			closeNotification={() => setOpenNotif(false)}
			color={notifColor}
		/>
		<div style={{ marginTop: "20px" }}>
			<div {...getRootProps({ style })}>
				<input {...getInputProps()} />
				{isDragActive ? (
					<p style={{ fontSize: 16 }}>
						Déposer le fichier ici ... (format .xsl, .xlsx,
						.ods, .csv)
					</p>
				) : (
					<p style={{ fontSize: 16 }}>
						Déposer le fichier ici ou selectionner le en
						cliquant ici (format .xls, .xlsx, .ods, .csv)
					</p>
				)}
			</div>
			<GridContainer>
				<GridItem
					xs={12}
					sm={12}
					md={12}
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						marginTop: "1.5%",
						fontSize: 18,
						marginBottom: "15px",
					}}
				>
					{file?.name}
				</GridItem>
				<GridItem
					xs={12}
					sm={12}
					md={12}
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Button
						onClick={readFile}
						disabled={!file}
						color="primary"
					>
						Lire le fichier
					</Button>
				</GridItem>
				<Card
					style={{
						boxShadow: "none",
						marginTop: 0,
						paddingTop: 0,
					}}
				>
					<CardBody>
						{fileUpload && (
							<Table
								tableHeaderColor="primary"
								tableType="user"
								tableHead={["Ligne"].concat(
									fileTemplate
										.map(it => it.title + (it.allowNull ? "" : " *"))
										.concat(["Statut"])
								)}
								tableData={
									fileData &&
									fileData.map(line => {
										const lineList = { ligne: line.line };
										fileTemplate.forEach(
											it =>
											(lineList[it.title] =
												line.data[it.key])
										);
										const missingData =
											fileTemplate
												.map(it => line.data[it.key] === null && !it.allowNull)
												.reduce((a, b) => a + b, 0) > 0;
										if (missingData && !errorFileData) {
											setErrorFileData(true);
										}
										lineList.status = missingData ? (
											<Tooltip title="Un ou plusieurs champs sont manquant">
												<WarningIcon
													style={{ color: "#f0ad4e" }}
												/>
											</Tooltip>
										) : (
											<CheckCircleIcon
												style={{ color: "#5cb85c" }}
											/>
										);
										return Object.values(lineList);
									})
								}
							/>
						)}
					</CardBody>
					{fileUpload && (
						<GridItem
							xs={12}
							sm={12}
							md={12}
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								marginTop: "2%",
								marginBottom: "2%",
							}}
						>
							<Button
								onClick={uploadGroup}
								disabled={errorFileData}
								color="primary"
							>
								Ajouter les appartements
							</Button>
						</GridItem>
					)}
					{uploadGroupStart && (
						<GridItem
							xs={12}
							sm={12}
							md={12}
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								marginBottom: "1%",
							}}
						>
							<Box position="relative" display="inline-flex">
								<CircularProgress variant="determinate" value={progress} />
								<Box
									top={0}
									left={0}
									bottom={0}
									right={0}
									position="absolute"
									display="flex"
									alignItems="center"
									justifyContent="center"
								>
									<Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
										progress,
									)}%`}</Typography>
								</Box>
							</Box>
						</GridItem>
					)}
					{errorUpload &&
						errorUpload.map((error, i) => (
							<GridItem
								xs={12}
								sm={12}
								md={12}
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
								key={i}
							>
								<WarningIcon style={{ color: "#f0ad4e" }} />
								<div style={{ marginLeft: "10px" }}>
									{error}
								</div>
							</GridItem>
						))}
				</Card>
			</GridContainer>
		</div>
		</>
	);
	
}