/* eslint-disable react/no-children-prop */
import React, { useEffect } from "react";

import moment from "moment";
import localization from "moment/locale/fr";

// Other front packages
import Tabs from "components/CustomTabs/CustomTabs.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";

import AssignmentIcon from "@material-ui/icons/Assignment";
import PostAddIcon from "@material-ui/icons/PostAdd";
import ViewListIcon from "@material-ui/icons/ViewList";

import BookingListView from "./TabViews/BookingListView";
import SubscriptionListView from "./TabViews/SubscriptionListView";
import BookingRulesView from "./TabViews/BookingRulesView";

import AccessRestrictionView from "./TabViews/AccessRestrictionView";
import EventBusy from "@material-ui/icons/EventBusy";

moment.updateLocale("fr", localization);

export default function Bookings(props) {

  return (
    <div>
      <GridContainer>
        <Tabs
          tabs={[
            {
              // tabWidth: 'auto',
              tabName: "Réservations",
              tabIcon: AssignmentIcon,
              tabContent: <BookingListView />,
            },
            {
              // tabWidth: 'auto',
              tabName: "Abonnements",
              tabIcon: PostAddIcon,
              tabContent: <SubscriptionListView />,
            },
            {
              // tabWidth: 140,
              tabName: "Règles",
              tabIcon: ViewListIcon,
              tabContent: <BookingRulesView />,
            },
            {
              tabName: "Fermeture planifiée",
              tabIcon: EventBusy,
              tabContent: <AccessRestrictionView />,
            },
          ]}
        />
      </GridContainer>
    </div>
  );
}