import { get_axs, post_axs, put_axs, remove_axs } from "helpers/fetcher";

export const b2ebikeServices = {
  add,
  update,
  destroy,
  getAll,
};

async function add(data) {
  return post_axs(
    "/b2ebikes",
    new URLSearchParams({
      ...data,
    })
  );
}

async function update(id, data) {
  return put_axs(
    `/b2ebikes/${id}`,
    new URLSearchParams({
      ...data,
    })
  );
}

async function getAll() {
  return get_axs("/b2ebikes");
}


async function destroy(id) {
  return remove_axs(`/b2ebikes/${id}`);
}
