import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { GetApp } from "@material-ui/icons";

import Button from "components/CustomButtons/Button.js";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import moment from "moment";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const RowRegister = (props) => {
  const {
    row,
    myself,
    toggleValidationModal,
    downloadProofOfResidence,
    userState,
    handleUpdateOccupationStatus,
  } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.callTitle === "MR"
            ? "Monsieur"
            : row.callTitle === "MM"
              ? "Madame"
              : "Non renseigné"}
        </TableCell>
        <TableCell align="right">{row.lastName}</TableCell>
        <TableCell align="right">{row.firstName}</TableCell>
        <TableCell align="right">{row.email}</TableCell>
        <TableCell align="right">
          {row.mobilePhone ? row.mobilePhone : "Non renseigné"}
        </TableCell>
        <TableCell align="right">
          {moment(row.createdAt).format("DD MMMM YYYY [à] HH[h]mm")}
        </TableCell>
        <TableCell align="right">
          {row.activatedAt
          ? moment(row.activatedAt).format("DD MMMM YYYY [à] HH[h]mm")
          : "Jamais"}
        </TableCell>
        <TableCell align="right">
          {row.logedinAt
            ? moment(row.logedinAt).format("DD MMMM YYYY [à] HH[h]mm")
            : "Jamais"}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Rattaché à :
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Bâtiment</TableCell>
                    <TableCell>Appartement</TableCell>
                    {userState === "denied" && (
                      <TableCell>Raison du refus</TableCell>
                    )}
                    <TableCell>Statut d'occupation</TableCell>
                    <TableCell>Nom du propriétaire (si locataire)</TableCell>
                    <TableCell>Justificatif de domicile</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row?.estateToUsers
                    ?.filter(eTu => eTu.state === userState)
                    .map((data, key) => (
                      <TableRow key={key}>
                        <TableCell component="th" scope="row">
                          {data?.sector?.name}
                        </TableCell>
                        <TableCell>{data?.group?.title}</TableCell>
                        {userState === "denied" && (
                          <TableCell>{data?.note}</TableCell>
                        )}
                        <TableCell>
                          <Select
                            value={data?.occupationStatus}
                            onChange={event =>
                              handleUpdateOccupationStatus(
                                row.uid,
                                event.target.value,
                                data?.groupId
                              )
                            }
                            disabled={!(myself?.rights?.role_superadmin ||
                              myself.rights.role_users_edit)}
                          >
                            <MenuItem value={"OWNER"}>Propriétaire</MenuItem>
                            <MenuItem value={"TENANT"}>Locataire</MenuItem>
                          </Select>
                        </TableCell>

                        <TableCell>{data.ownerName || "N/A"}</TableCell>
                        <TableCell>
                          {data.occupationStatus === "TENANT" ? (
                            <IconButton
                              aria-label="download"
                              onClick={() =>
                                downloadProofOfResidence(
                                  data.uuid,
                                  data?.group?.id
                                )
                              }
                            >
                              <GetApp />
                            </IconButton>
                          ) : (
                            "Aucun"
                          )}
                        </TableCell>
                        <TableCell>
                          {(myself?.rights?.role_superadmin ||
                            myself.rights.role_users_edit) && (<Button
                              size="sm"
                              color="info"
                              onClick={() =>
                                toggleValidationModal(row, data.uuid)
                              }
                              round={false}
                            >
                              Gérer
                            </Button>)
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

RowRegister.propTypes = {
  row: PropTypes.object,
  myself: PropTypes.object,
  toggleValidationModal: PropTypes.func,
  downloadProofOfResidence: PropTypes.func,
  userState: PropTypes.string,
  handleUpdateOccupationStatus: PropTypes.func,
}

const CollapsibleTableRegister = ({
  usersList,
  userState,
  myself,
  downloadProofOfResidence,
  toggleValidationModal,
  handleUpdateOccupationStatus,
}) => {
  return (
    <TableContainer component={Paper} style={{ marginTop: "2%" }}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Civilité</TableCell>
            <TableCell align="right">Nom</TableCell>
            <TableCell align="right">Prénom</TableCell>
            <TableCell align="right">Email</TableCell>
            <TableCell align="right">Téléphone</TableCell>
            <TableCell align="right">Inscrit le</TableCell>
            <TableCell align="right">Activé le</TableCell>
            <TableCell align="right">Dernière connexion</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {usersList.map((row, key) => (
            <RowRegister
              key={key}
              row={row}
              myself={myself}
              downloadProofOfResidence={downloadProofOfResidence}
              toggleValidationModal={toggleValidationModal}
              userState={userState}
              handleUpdateOccupationStatus={handleUpdateOccupationStatus}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

CollapsibleTableRegister.propTypes = {
  usersList: PropTypes.array,
  userState: PropTypes.string,
  myself: PropTypes.object,
  downloadProofOfResidence: PropTypes.func,
  toggleValidationModal: PropTypes.func,
  handleUpdateOccupationStatus: PropTypes.func,
};

export default CollapsibleTableRegister;