import React, { useEffect } from "react";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table.js";
import { ReactComponent as AddIcon } from "assets/img/svg/AddIcon.svg";
import Pagination from "@material-ui/lab/Pagination";
import Button from "components/CustomButtons/Button.js";
import ConfirmModal from "components/ConfirmModal";
import BuildingListLogic from "../TabLogics/BuildingListLogic";
import styles from "assets/jss/material-dashboard-react/views/newsStyle.js";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Snackbar from "components/Snackbar/Snackbar.js";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import NotificationsIcon from '@material-ui/icons/Notifications';
import {
	makeStyles,
	Button as MUIButton,
	Modal,
	Backdrop,
	Container,
	CssBaseline,
	Typography,
	Grid,
	InputLabel,
	TextField
} from "@material-ui/core";

const useStyles = makeStyles(styles);

const paginationStyles = makeStyles(() => ({
	ul: {
		"& .MuiPaginationItem-root": {
			color: "#6E6FA9",
		},
		"& .Mui-selected": {
			backgroundColor: "#6E6FA9",
			color: "#E7E6ED",
		},
	},
}));

export default function BuildingListView(props) {
	
	const classes = useStyles();
	const paginationClasses = paginationStyles();
	
	const {
		attributeDestroy,
		attributesCount,
		attributesList,
		editModalOpened,
		limitAttributes,
		modalType,
		newAttributesInfo,
		notifColor,
		notifMessage,
		openNotif,
		page,
		deleteAttribute,
		handleFormChange,
		handlePagination,
		refreshData,
		setOpenNotif,
		submitNewAttribute,
		toggleConfirmAttributeDestroy,
		toggleModal
	} = BuildingListLogic();
	
	useEffect(() => {
		refreshData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.refresh]);
	
	return (
		<>
			<Snackbar
				place='tr'
				message={notifMessage}
				open={openNotif}
				close
				icon={notifColor === 'success' ? CheckCircleIcon : (notifColor === 'danger' ? WarningIcon : NotificationsIcon)}
				closeNotification={() => setOpenNotif(false)}
				color={notifColor}
			/>
			{attributeDestroy && (
				<Modal
					BackdropComponent={Backdrop}
					BackdropProps={{ timeout: 500 }}
					className={classes.modal}
					onClose={() => toggleConfirmAttributeDestroy(null)}
					open={attributeDestroy ? true : false}
				>
					<div className={classes.paper}>
						<ConfirmModal
							closeModal={() => toggleConfirmAttributeDestroy(null)}
							kind={`Supprimer le bâtiment "${attributeDestroy.name}" ?`}
							makeAction={() => deleteAttribute(attributeDestroy.uuid)}
						/>
					</div>
				</Modal>
			)}
			{editModalOpened && newAttributesInfo && (
				<Modal
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}
					className={classes.modal}
					onClose={() => toggleModal()}
					open={editModalOpened}
				>
					<div className={classes.paper}>
						<Container component="main" maxWidth="md" style={{
							overflowY: "auto",
						}}>
							<CssBaseline />
							<div>
								<div className={classes.headerModal}>
									{modalType ? (
										<Typography variant="h5">Modifier le bâtiment</Typography>
									) : (
										<Typography variant="h5">Ajouter un bâtiment</Typography>
									)}
									<IconButton
										size="small"
										aria-label="delete"
										onClick={() => toggleModal()}
									>
										<CloseIcon />
									</IconButton>
								</div>
								<form className={classes.form} onSubmit={submitNewAttribute} noValidate>
									<Grid container spacing={3}>
										<Grid item md={12}>
											<InputLabel>Titre *</InputLabel>
											<TextField
												autoComplete="fname"
												defaultValue={newAttributesInfo.title}
												name="title"
												required
												fullWidth
												id="title"
												onChange={event =>
													handleFormChange("title", event.target.value)
												}
												autoFocus
											/>
										</Grid>
										<Grid item md={3}>
											<InputLabel>N° de rue</InputLabel>
											<TextField
												maxLength={8}
												autoComplete="fname"
												defaultValue={newAttributesInfo.streetNumber}
												name="streetNumber"
												required
												fullWidth
												id="streetNumber"
												onChange={event =>
													handleFormChange("streetNumber", event.target.value)
												}
												autoFocus
											/>
										</Grid>
										<Grid item md={9}>
											<InputLabel>Nom de rue</InputLabel>
											<TextField
												maxLength={64}
												autoComplete="fname"
												defaultValue={newAttributesInfo.streetName}
												name="streetName"
												required
												fullWidth
												id="streetName"
												onChange={event =>
													handleFormChange("streetName", event.target.value)
												}
												autoFocus
											/>
										</Grid>
									</Grid>
									<Button
										type="submit"
										fullWidth
										variant="contained"
										color="primary"
										className={classes.submit}
										disabled={!newAttributesInfo?.title?.length}
									>
										Enregister
									</Button>
								</form >
							</div >
						</Container >
					</div>
				</Modal>
			)}
			<GridItem xs={12} sm={12} md={12}>
				<CardBody>
					<GridContainer>
						<GridItem xs={12} sm={12} md={12}>
							<MUIButton
								className={classes.button}
								onClick={() => toggleModal()}
								startIcon={<AddIcon />}
							>
								Ajouter un bâtiment
							</MUIButton>
						</GridItem>
					</GridContainer>
				</CardBody>
			
				<div>
					{attributesList && attributesList.length === 0 ? (
						<h4>Aucun bâtiment à afficher</h4>
					) :
						(<Table
							tableHeaderColor="primary"
							tableType="links"
							tableHead={
								[
									"Titre",
									"N° de rue",
									"Nom de rue",
									""
								]
							}
							tableData={
								attributesList &&
								attributesList.map(attribute => {
									const attributeList = {
										title: attribute?.name,
										streetNumber: attribute?.streetNumber,
										streeName: attribute?.streetName,
										actions: (
											<div style={{
												display: "flex",
											}}>
												<Button
													style={{ marginLeft: 'auto', }}
													size="sm"
													onClick={() => toggleModal(attribute)}
													color="warning"
												>
													Éditer
												</Button>
												<Button
													size="sm"
													style={{ marginLeft: '2%' }}
													onClick={() => toggleConfirmAttributeDestroy(attribute)}
													color="danger"
												>
													Supprimer
												</Button>
											</div>
										),
									};
									return Object.values(attributeList);
								})
							}
						/>)
					}
					{attributesCount > 0 && (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								paddingTop: 100,
							}}
						>
							<Pagination
								count={Math.ceil(attributesCount / limitAttributes)}
								page={page}
								onChange={(event, pageNumber) => {
									handlePagination(pageNumber);
								}}
								classes={{ ul: paginationClasses.ul }}
								size="large"
							/>
						</div>
					)}
				</div>
			</GridItem>
		</>
	)
}