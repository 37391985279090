import React, { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "react-router-dom";
import { loadState, saveState } from "../helpers/localStorage";
import CloseIcon from "@material-ui/icons/Close";

import { modalActions, paginationActions, siteActions, userActions } from "actions";
import { moduleServices, privilegeService, siteService } from "services";
import { Backdrop, CircularProgress, IconButton, TextField } from "@material-ui/core";

import Button from "@material-ui/core/Button";

// creates a beautiful scrollbar
import Autocomplete from "@material-ui/lab/Autocomplete";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import FormEstates from "components/FormUsers/FormEstates";
import Sidebar from "components/Sidebar/Sidebar.js";

import Modal from "@material-ui/core/Modal";

import RoutesParser from "routes.js";
import { history } from "../helpers";
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

import logo from "assets/img/svg/ouba-white-colored-logo.svg";
import PrivateRoute from "components/PrivateRoutes/PrivatesRoutes";
import AddIcon from "@material-ui/icons/Add";

const textStyles = makeStyles({
  formControlRoot: {
    fontFamily: "futura",
    color: "#6E6FA9",
    borderRadius: "7px",
    "& label.Mui-focused": {
      color: "#6E6FA9",
    },
  },
  inputLabelRoot: {
    color: "#6E6FA9",
    fontFamily: "futura",
    "&.focused": {
      color: "#6E6FA9",
    },
  },
});

let ps;

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
  // styles
  const classes = useStyles();
  const textClasses = textStyles();
  const dispatch = useDispatch();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = createRef();

  // Redux store
  let routes = RoutesParser();
  // Redux state imports
  const { loggingIn, loggedIn } = useSelector(state => state.authentication);
  const { open } = useSelector(state => state.displaySitesModal);

  const { loadedMyself } = useSelector(state => state.myself);

  // states and functions
  const sites = loadState("sites");
  const color = "brown";
  const [selectedSite, setSelectedSite] = useState(loadState("site"));
  const [isSuperAdmin, setIsSuperAdmin] = useState(undefined);

  const [editModalOpened, setEditModalOpened] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [newEstateInfo, setNewEstateInfo] = useState(undefined);

  // const [fixedClasses, setFixedClasses] = useState("dropdown show");
  const [mobileOpen, setMobileOpen] = useState(false);
  // const [modalOpen, setModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [moduleList, setModuleList] = useState([]);
  const [moduleFilter, setModuleFilter] = useState([]);

  useEffect(() => {
    (async () => {
      const module = await moduleServices.getAll();
      if (module) {
        setModuleList(module);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn, open]);

  const switchRoutes = (
    <Switch>
      {routes.map((prop, key) => {
        if (prop.layout === "/admin" && selectedSite) {
          return (
            <PrivateRoute
              path={prop.layout + prop.path}
              component={prop.component}
              refresh={refresh}
              key={key * 2}
            />
          );
        }
        return null;
      })}
      {routes.map((prop, key) => {
        if (prop.settings && prop.layout === "/admin" && selectedSite) {
          return (
            <PrivateRoute
              path={prop.layout + prop.settings}
              component={prop.componentSettings}
              refresh={refresh}
              key={key * 2 + 1}
            />
          );
        }
        return null;
      })}
      {/* <Redirect from="/admin" to="/admin/users" /> */}
    </Switch>
  );

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  
  const checkIsSuperAdmin = (user) => {
    return user?.sites_to_admins?.find(it => it?.role_superadmin === true)
  }

  useEffect(() => {
    if (loggedIn && !loadedMyself && selectedSite) {
      dispatch(userActions.getSelf());
    }
    return () => {
      if (!loggedIn && !loggingIn) {
        localStorage.clear();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedMyself, loggedIn, loggingIn, selectedSite]);

  useEffect(() => {
    (async () => {
      let admin = isSuperAdmin;
      const myself = await privilegeService.getSelf();
      if (admin === undefined) {
        admin = false;
        if (checkIsSuperAdmin(myself)) {
          admin = true;
        }
        setIsSuperAdmin(admin);
      }
      if (sites && loggedIn && open && sites.length === 1 && admin === false) {
        selectSite(sites[0]);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn, open]);

  const toggleModal = () => {
    setFormValid(false);
    const estateInfo = {
      title: "",
      estateCode: "",
      color_main: "#000000",
      color_background: "#000000",
      address: "",
      city: "",
      zipcode: "",
      country: "FR",
      timezone: "Europe/Paris",
      credit_wallet_enable: true,
      currency: "EUR",
      web_host_name: "",
      maintenance_mode: null,
      disabled: 0,
    };
    setNewEstateInfo(estateInfo);
    setEditModalOpened(!editModalOpened);
  };

  const handleFormChange = (key, value) => {
    let estateInfo = { ...newEstateInfo };
    estateInfo[key] = value;
    setNewEstateInfo(estateInfo);
    isFormValid(estateInfo);
  };

  const submitNewEstate = async event => {
    event.preventDefault();
    let estateInfo = { ...newEstateInfo };
    estateInfo.color_main = estateInfo.color_main[0] === "#" ? estateInfo.color_main.slice(1) : estateInfo.color_main;
    estateInfo.color_background = estateInfo.color_background[0] === "#" ? estateInfo.color_background.slice(1) : estateInfo.color_background;
    await siteService.add(estateInfo);
    dispatch(siteActions.getAll());
    setEditModalOpened(!editModalOpened);
    setFormValid(true);
  };

  const isFormValid = (estate) => {
    if (!estate?.title?.length || !estate?.estateCode?.length ||
      !estate?.color_main?.length || !estate?.color_background?.length ||
      !estate?.address?.length || !estate?.country?.length ||
      !estate?.timezone?.length || !estate?.currency?.length ||
      !estate?.web_host_name?.length)
      setFormValid(false);
    else
      setFormValid(true);
  };

  const selectSite = site => {
    saveState("site", site);
    setSelectedSite(site);
    if (site) {
      setRefresh(!refresh);
      dispatch(userActions.getSelf());
      dispatch(paginationActions.setPageUsers(1));
      setRefresh(!refresh);
      dispatch(paginationActions.setPageBookings(1));
      dispatch(siteActions.getSite(site));
    }
  };

  const renderButtons = sites => {
    let key = 0;
    return (
      <div>
        {isSuperAdmin && (<Autocomplete
          multiple
          id="tags-groups"
          options={moduleList}
          getOptionLabel={option => option.title}
          onChange={(_, value) => {
            setModuleFilter(value);
          }}
          value={moduleFilter}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              classes={{
                root: textClasses.formControlRoot,
              }}
              label="Filtrer les résidences par modules"
              placeholder="Modules sélectionnés"
              InputProps={{
                ...params.InputProps,
                className: classes.input,
              }}
              InputLabelProps={{
                classes: {
                  root: textClasses.inputLabelRoot,
                },
              }}
            />
          )}
        />)
        }
        {isSuperAdmin && (
          <div
            className={classes.sitesButtonsContainer}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Button
              className={classes.sitesButtons}
              // variant="outlined"
              style={{
                background: "#FFFFFF",
                borderRadius: 3,
                color: "#3d406e",
                height: 48,
                padding: "0 30px",
                boxShadow: "0px 0px 15px #ddd",
                border: "2px dashed #ddd",
              }}
              size="medium"
              onClick={() => toggleModal()}
            >
              Nouvelle résidence
              <AddIcon style={{ marginLeft: "2%", marginTop: "-3px" }} />
            </Button>
          </div>)
        }
        {sites
          .filter(site => !moduleFilter.length || site.features
            .find(feature => moduleFilter
              .find(it => feature.module.title === it.title)))
          .map(site => {
            return (
              <div
                className={classes.sitesButtonsContainer}
                key={(key += 1)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}>
                <Button
                  className={classes.sitesButtons}
                  key={(key += 1)}
                  // variant="outlined"
                  style={{
                      background: "#FFFFFF",
                      borderRadius: 3,
                      color: `#${site.color_main}`,
                      height: 70,
                      padding: "0 30px",
                      boxShadow: "0px 0px 15px #ddd",
                      border: "1px solid #ddd",
                      display: "inline-block",
                      position: "relative"
                    }}
                  size="medium"
                  onClick={() => selectSite(site)}
                >
                  <div>{site.title}</div>
                  <hr/>
                  <div><small>{site.zipcode} {site.city}</small></div>
                </Button>
              </div>
            );
          })}
      </div>
    );
  };

  const modalBody = (
    <div className={classes.modal}>
      <div style={{ display: "flex", paddingTop: 20 }}>
        <div style={{ display: "row", alignSelf: "center", marginLeft: selectedSite ? 55 : 0 }}>
          <h2 id="simple-modal-title">Sélectionnez la résidence</h2>
        </div>
        {selectedSite && (
          <IconButton
            style={{ height: 50, width: 50, marginTop: 5, marginLeft: 5 }}
            onClick={() => dispatch(modalActions.hideSitesModal())}
          >
            <CloseIcon />
          </IconButton>
        )}
      </div>
      {sites && renderButtons(sites)}
    </div>
  );

  if (loggedIn && isSuperAdmin !== undefined) {
    return (
      <div className={classes.wrapper}>
        {editModalOpened && newEstateInfo && (
          <Modal
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            className={classes.modal}
            open={editModalOpened}
          >
            <div className={classes.paper} style={{
              maxHeight: "80%",
            }}>
              <FormEstates
                newEstateInfo={newEstateInfo}
                formValid={formValid}
                handleFormChange={handleFormChange}
                submitNewEstate={submitNewEstate}
                toggleModal={toggleModal}
              />
            </div>
          </Modal>
        )}
        {sites && loggedIn && open &&
          !(sites.length === 1 && isSuperAdmin === false) && (
            <div className={classes.modal}>
              <Modal
                scrollable="true"
                open={open}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
                <div className={classes.modal}>{modalBody}</div>
              </Modal>
            </div>
          )}
        <Sidebar
          routes={routes}
          logo={logo}
          headTitle={selectedSite && selectedSite.title}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          bgColor={"blue"}
          color={color}
          disabledEstate={(selectedSite && selectedSite.disabled) ?? false}
          {...rest}
        />
        <div className={classes.mainPanel} ref={mainPanel}>
          <Navbar
            routes={routes}
            handleDrawerToggle={handleDrawerToggle}
            {...rest}
          />
          {/* On the /maps route we want the map to be on full screen - this is not possible if the content and container classes are present because they have some paddings which would make the map smaller */}
          <div className={getRoute() ? classes.content : classes.map}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
          {getRoute() ? <Footer /> : null}
          {/* <FixedPlugin
          handleImageClick={handleImageClick}
          handleColorClick={handleColorClick}
          bgColor={color}
          bgImage={image}
          handleFixedClick={handleFixedClick}
          fixedClasses={fixedClasses}
        /> */}
        </div>
      </div>
    );
  }
  return (
    <div className={classes.wrapper}>
      {isSuperAdmin !== undefined && <CircularProgress />}
    </div>
  );
}
