import React, { useEffect } from "react";
import moment from "moment";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table.js";
import { ReactComponent as AddIcon } from "assets/img/svg/AddIcon.svg";
import Pagination from "@material-ui/lab/Pagination";
import Button from "components/CustomButtons/Button.js";
import ConfirmModal from "components/ConfirmModal";
import AccessRestrictionLogic from "../TabLogics/AccessRestrictionLogic";
import styles from "assets/jss/material-dashboard-react/views/newsStyle.js";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Snackbar from "components/Snackbar/Snackbar.js";
import {
	makeStyles,
	Button as MUIButton,
	Modal,
	Backdrop,
	Container,
	CssBaseline,
	Typography,
	Grid,
	InputLabel,
	TextField,
	MenuItem,
	Select
} from "@material-ui/core";

const listDateFormat = "DD MMMM YYYY [à] HH[h]mm";

const useStyles = makeStyles(styles);

const paginationStyles = makeStyles(() => ({
	ul: {
		"& .MuiPaginationItem-root": {
			color: "#6E6FA9",
		},
		"& .Mui-selected": {
			backgroundColor: "#6E6FA9",
			color: "#E7E6ED",
		},
	},
}));

export default function AccessRestrictionView(props) {
	
	const classes = useStyles();
	const paginationClasses = paginationStyles();
	
	const {
		itemsCount,
		itemsList,
		limit,
		newItemInfo,
		notifColor,
		notifMessage,
		openNotif,
		page,
		handlePagination,
		refreshData,
		setOpenNotif,
		handleFormChange,
		editModalOpened,
		toggleModal,
		modalType,
		submitItem,
		locationList,
		toggleConfirmItemDestroy,
		formValid,
		modalMessage,
		deleteItem,
		itemDestroy
	} = AccessRestrictionLogic();
	
	useEffect(() => {
		refreshData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.refresh]);
	
	return (
		<>
		<Snackbar
				place="tr"
				message={notifMessage}
				open={openNotif}
				close
				icon={
						notifColor === "success"
								? CheckCircleIcon
								: notifColor === "danger"
										? WarningIcon
										: NotificationsIcon
				}
				closeNotification={() => setOpenNotif(false)}
				color={notifColor}
		/>
		{itemDestroy && (
				<Modal
						BackdropComponent={Backdrop}
						BackdropProps={{timeout: 500}}
						className={classes.modal}
						onClose={() => toggleConfirmItemDestroy(null)}
						open={!!itemDestroy}
				>
						<div className={classes.paper}>
								<ConfirmModal
										closeModal={() => toggleConfirmItemDestroy(null)}
										kind={'Supprimer la règle'}
										makeAction={() => deleteItem(itemDestroy.uuid)}
								/>
						</div>
				</Modal>
		)}
		{editModalOpened && newItemInfo && (
			<Modal
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
				className={classes.modal}
				onClose={() => toggleModal()}
				open={editModalOpened}
			>
				<div className={classes.paper}>
					<Container component="main" maxWidth="md" style={{
						overflowY: "auto",
					}}>
						<CssBaseline />
						<div>
							<div className={classes.headerModal}>
								{modalType ? (
									<Typography variant="h5">Modifier la période de fermeture</Typography>
								) : (
									<Typography variant="h5">Ajouter une période de fermeture</Typography>
								)}
								<IconButton
									size="small"
									aria-label="delete"
									onClick={() => toggleModal()}
								>
									<CloseIcon />
								</IconButton>
							</div>
							<form className={classes.form} onSubmit={submitItem} noValidate>
								<Grid container spacing={3}>
									<Grid item md={6}>
										<InputLabel>Date de début</InputLabel>
										<TextField
											autoComplete="fname"
											defaultValue={newItemInfo?.dateBegin ? moment(newItemInfo?.dateBegin).format('YYYY-MM-DD HH:mm') : null}
											type="datetime-local"
											name="dateBegin"
											required
											fullWidth
											id="dateBegin"
											onChange={event =>
												handleFormChange("dateBegin", event.target.value)
											}
										/>
									</Grid>
									<Grid item md={6}>
										<InputLabel>Date de fin</InputLabel>
										<TextField
											maxLength={8}
											autoComplete="fname"
											defaultValue={newItemInfo?.dateEnd ? moment(newItemInfo?.dateEnd).format('YYYY-MM-DD HH:mm') : null}
											type="datetime-local"
											name="dateEnd"
											required
											fullWidth
											id="dateEnd"
											onChange={event =>
												handleFormChange("dateEnd", event.target.value)
											}
										/>
									</Grid>
									{locationList &&
										<Grid item md={12}>
											<InputLabel>Emplacement</InputLabel>
											<Select
												value={newItemInfo?.locationId ?? ''}
												id="select-type"
												fullWidth
												displayEmpty
												onChange={event =>
													handleFormChange("locationId", event.target.value)
												}
											>
												<MenuItem value={""}>
													<em>Aucune séléction</em>
												</MenuItem>
												{locationList &&
													locationList.map((it, i) => (
														<MenuItem key={i} value={it.id}>
															<div>{it.title}</div>
														</MenuItem>
													))}
											</Select>
										</Grid>
									}
									<Grid item md={12}>
										<InputLabel>Note/Raison</InputLabel>
										<TextField
											maxLength={64}
											autoComplete="fname"
											defaultValue={newItemInfo.note}
											name="note"
											required
											fullWidth
											id="note"
											onChange={event =>
												handleFormChange("note", event.target.value)
											}
											autoFocus
										/>
									</Grid>
								</Grid>
								<Button
									type="submit"
									fullWidth
									variant="contained"
									color="primary"
									className={classes.submit}
									disabled={!formValid}
								>
									Enregister
								</Button>
							</form >
						</div >
					</Container >
				</div>
			</Modal>
		)}
			<GridItem>
				<CardBody>
						<GridContainer>
								<GridItem xs={12} sm={12} md={12}>
										<MUIButton
												className={classes.button}
												onClick={() => toggleModal()}
												startIcon={<AddIcon/>}
												style={{width: "auto"}}
										>
												Ajouter une période de fermeture
										</MUIButton>
								</GridItem>
						</GridContainer>
				</CardBody>
				<GridItem xs={12} sm={12} md={12}>
					{itemsList && itemsList.length === 0 ? (
						<h4>Aucune fermeture planifiée à afficher</h4>
					) :
						(<Table
							tableHeaderColor="primary"
							tableType="booking"
							tableHead={
								[
									"Date début",
									"Date fin",
									"Emplacement",
									"Note/Raison",
									"Actions"
								]
							}
							tableData={
								itemsList &&
								itemsList.map(item => {
									const itemList = {
										dateBegin: moment(item?.dateBegin).format(listDateFormat),
										dateEnd: moment(item?.dateEnd).format(listDateFormat),
										location: item?.location?.title ?? "---",
										note: item?.note,
										actions: (
											<div style={{
												display: "flex",
											}}>
												<Button
													style={{ marginLeft: 'auto', }}
													size="sm"
													onClick={() => toggleModal(item)}
													color="warning"
												>
													Éditer
												</Button>
												<Button
													size="sm"
													style={{ marginLeft: '2%' }}
													onClick={() => toggleConfirmItemDestroy(item)}
													color="danger"
												>
													Supprimer
												</Button>
											</div>
										),
									};
									return Object.values(itemList);
								})
							}
						/>)
					}
					{itemsCount > 0 && (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								paddingTop: 100,
							}}
						>
							<Pagination
								count={Math.ceil(itemsCount / limit)}
								page={page}
								onChange={(event, pageNumber) => {
									handlePagination(pageNumber);
								}}
								classes={{ ul: paginationClasses.ul }}
								size="large"
							/>
						</div>
					)}
				</GridItem>
			</GridItem>
		</>
	)
}