import { get_axs, post_axs, put_axs, remove_axs } from "helpers/fetcher";

export const reservationA1001DoorServices = {
  add,
  update,
  destroy,
  getAll,
  getItem,
  setLocationToDoor,
  unsetLocationForDoor,
  setA1001ToDoor,
  unsetA1001ForDoor,
};

async function add(data) {
  return post_axs(
    "/modules/reservations/a1001/doors",
    new URLSearchParams({
      ...data,
    })
  );
}

async function update(doorUUID, data) {
  return put_axs(
    `/modules/reservations/a1001/doors/${doorUUID}`,
    new URLSearchParams({
      ...data,
    })
  );
}

async function destroy(doorUUID) {
  return remove_axs(`/modules/reservations/a1001/doors/${doorUUID}`);
}

async function getAll() {
  return get_axs(`/modules/reservations/a1001/doors`);
}

async function getItem(uuid) {
  return get_axs(`/modules/reservations/a1001/doors/${uuid}`);
}

async function setLocationToDoor(a1001UUID, uuid) {
  return post_axs(
    `/modules/reservations/a1001/doors/${uuid}/set/location/${a1001UUID}`
  );
}

async function unsetLocationForDoor(uuid, locationUUID) {
  return remove_axs(`/modules/reservations/a1001/doors/${uuid}/unset/location/${locationUUID}`);
}

async function setA1001ToDoor(a1001UUID, uuid) {
  return post_axs(
    `/modules/reservations/a1001/doors/${uuid}/set/a1001/${a1001UUID}`
  );
}

async function unsetA1001ForDoor(uuid) {
  return remove_axs(`/modules/reservations/a1001/doors/${uuid}/unset/a1001`);
}
