import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import {
  groupActions,
  sectorActions,
} from "actions";

import { userService, mailServices } from "services";

const MailsLogic = () => {
  const dispatch = useDispatch();

  const { myself } = useSelector(userState => userState.myself);
  const { groups } = useSelector(userState => userState.groupsFetching);
  const { sectors } = useSelector(userState => userState.sectorsFetching);

  const [openNotif, setOpenNotif] = useState(false);
  const [notifMessage, setNotifMessage] = useState("");
  const [notifColor, setNotifColor] = useState("info");

  const [refresh, setRefresh] = useState(false)

  const [mailModalOpened, setMailModalOpened] = useState(false)
  const [formValid, setFormValid] = useState(false)
  const [newMailInfo, setNewMailInfo] = useState(undefined)

  const [userList, setUserList] = useState(undefined)
  const [sectorFilter, setSectorFilter] = useState([]);
  const [groupFilter, setGroupFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState(undefined);

  useEffect(() => {
    return () => {
      dispatch(groupActions.getAllByEstate());
      dispatch(sectorActions.getAll());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    (async () => {
      let page = 1
      let count = 0
      let total_count = null
      let user = []
      while (total_count === null || count < total_count) {
        const users = await userService.getAllBySite(
          page, "", "accepted", sectorFilter.map(sector => `&sector=${sector.id}`),
          groupFilter.map(group => `&group=${group.id}`))
        if (users) {
          const tmpUser = users.data?.rows
            .filter(user => !statusFilter || user?.estateToUsers
              .find(it => it.occupationStatus === statusFilter.key))
          let i = 0
          for (let x of tmpUser) {
            user.push({ key: count + i, email: x.email })
            i += 1
          }
          count += users.data?.rows?.length
          if (total_count === null)
            total_count = users.data?.meta?.count
        } else {
          break
        }
        page += 1
      }
      setUserList(user)
      if (newMailInfo && mailModalOpened) {
        const mailInfo = { ...newMailInfo }
        mailInfo.users = user
        mailInfo.loading = false
        mailInfo.usersList = []
        mailInfo.autoCompleteKey += 1
        mailInfo.contactEmail = `${myself?.first_name} ${myself?.last_name} <${myself?.email}>`
        mailInfo.users.sort((a, b) => b.email > a.email ? -1 : 1)
        setNewMailInfo(mailInfo)
        isFormValid(mailInfo)
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, sectorFilter, groupFilter, statusFilter]);

  const toggleModal = () => {
    setSectorFilter([])
    setGroupFilter([])
    setStatusFilter(null)
    setFormValid(false)
    const mailInfo = {
      title: '',
      content: '',
      users: userList,
      contactEmail: `${myself?.first_name} ${myself?.last_name} <${myself?.email}>`,
      usersList: [],
      dialog: false,
      autoCompleteKey: 0,
      loading: false,
    }
    mailInfo.users.sort((a, b) => b.email > a.email ? -1 : 1)
    setNewMailInfo(mailInfo)
    setMailModalOpened(!mailModalOpened)
  };

  const submitNewMail = async (event) => {
    event.preventDefault()
    const mailInfo = {
      recipients: [newMailInfo.users.map(it => it.email)],
      content: newMailInfo.content,
      subject: newMailInfo.title,
      sender: newMailInfo.contactEmail,
    }
    setNewMailInfo({ ...newMailInfo, loading: true })
    const res = await mailServices.sendMailToResidents(mailInfo)
    setNewMailInfo({ ...newMailInfo, loading: false })
    refreshData()
    setMailModalOpened(!mailModalOpened)
    setFormValid(true)
    if (res) {
      openNotification("Les mails ont été envoyé !", 'success')
    } else {
      openNotification("Une erreur est survenu lors de l'envoi des mail(s). Veuillez ressayer ultérieurement", 'danger')
    }
  }

  const handleFormChange = (key, value) => {
    const mailInfo = { ...newMailInfo }
    if (key === 'usersList') {
      mailInfo[key] = mailInfo.usersList.filter((user) => user.key !== value.key)
      mailInfo.users.push(value)
      mailInfo.users.sort((a, b) => b.email > a.email ? -1 : 1)
      mailInfo.autoCompleteKey += 1
    } else if (key === 'users') {
      mailInfo[key] = mailInfo.users.filter((user) => user.key !== value.key)
      mailInfo.usersList.push(value)
      mailInfo.usersList.sort((a, b) => b.email > a.email ? -1 : 1)
    } else if (key === 'dialog') {
      mailInfo[key] = !mailInfo[key]
    } else {
      mailInfo[key] = value
    }
    setNewMailInfo(mailInfo)
    isFormValid(mailInfo)
  }

  const isFormValid = (data) => {
    if (!data?.title?.length || !data?.content?.length || !data?.users?.length)
      setFormValid(false)
    else
      setFormValid(true)
  }

  const openNotification = (msg, color = "info", time = 6) => {
    setOpenNotif(false);
    setNotifMessage(msg);
    setNotifColor(color);
    setTimeout(() => setOpenNotif(false), time * 1000);
    setTimeout(() => setOpenNotif(true), 100);
  };

  const refreshData = () => setRefresh(!refresh);

  const handleUserFiltering = (type, value) => {
    switch (type) {
      case "sector":
        setSectorFilter(value);
        break;
      case "group":
        setGroupFilter(value);
        break;
      case "status":
        setStatusFilter(value);
        break;
      default:
        break;
    }
  };

  return {
    formValid,
    groupFilter,
    groups,
    mailModalOpened,
    myself,
    newMailInfo,
    notifColor,
    notifMessage,
    openNotif,
    sectorFilter,
    sectors,
    statusFilter,
    userList,
    handleFormChange,
    handleUserFiltering,
    refreshData,
    setOpenNotif,
    submitNewMail,
    toggleModal,
  };
};

export default MailsLogic;
