import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  classifiedPublicationServices,
  classifiedCategoryServices,
  mailServices,
} from "services";

const ClassifiedLogic = () => {

  const { myself } = useSelector(userState => userState.myself);

  const limitClassified = 10
  const direction = [{ id: 1, title: 'Offre', key: 'offer' },
  { id: 2, title: 'Requête', key: 'request' }]

  const [refresh, setRefresh] = useState(false);

  const [openNotif, setOpenNotif] = useState(false);
  const [notifMessage, setNotifMessage] = useState("");
  const [notifColor, setNotifColor] = useState("info");

  const [page, setPage] = useState(1)
  const [classifiedList, setClassifiedList] = useState(undefined)
  const [classifiedCount, setClassifiedCount] = useState(undefined)
  const [categories, setCategories] = useState(undefined)
  const [categoriesFilter, setCategoriesFilter] = useState([])
  const [directionFilter, setDirectionFilter] = useState([])

  const [editModalOpened, setEditModalOpened] = useState(false);
  const [newClassifiedInfo, setNewClassifiedInfo] = useState(undefined);

  useEffect(() => {
    (async () => {
      const category = await classifiedCategoryServices.getAll()
      if (category) {
        setCategories(category)
      }
      let publication = await classifiedPublicationServices.getAll()
      if (publication) {
        publication = publication.filter(it =>
          (!directionFilter?.length || directionFilter.find(dir => dir.key === it.direction)) &&
          (!categoriesFilter?.length || categoriesFilter.find(cat => cat.id === it.category_id)))
        setClassifiedCount(publication.length)
        setClassifiedList(publication.slice(limitClassified * (page - 1), limitClassified * (page - 1) + limitClassified))
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, page]);

  const refreshData = () => setRefresh(!refresh);

  const handlePagination = page => {
    setPage(page);
  };

  const openNotification = (msg, color = "info", time = 6) => {
    setOpenNotif(false);
    setNotifMessage(msg);
    setNotifColor(color);
    setTimeout(() => setOpenNotif(false), time * 1000);
    setTimeout(() => setOpenNotif(true), 100);
  };

  const toggleModal = (classified = null) => {
    const classifiedInfo = {
      publication: classified,
      reason: '',
    };
    setNewClassifiedInfo(classifiedInfo);
    setEditModalOpened(!editModalOpened);
  };

  const handleFormChange = (key, value, idx = 0) => {
    let classifiedInfo = { ...newClassifiedInfo };
    classifiedInfo[key] = value;
    setNewClassifiedInfo(classifiedInfo);
  };

  const submitNewClassified = async event => {
    event.preventDefault();
    if (newClassifiedInfo?.publication?.uuid) {
      if (await classifiedPublicationServices.destroy(newClassifiedInfo.publication.uuid)) {
        if (newClassifiedInfo?.publication?.user?.email) {
          const mailInfo = {
            recipients: [newClassifiedInfo.publication?.user.email],
            content: `L'annonce ${newClassifiedInfo?.publication?.title} que vous avez publiée a été supprimée.\n
            Raison de la suppression: ${newClassifiedInfo.reason}\n\n`,
            subject: "Ouba, Votre annonce a été supprimé",
            sender: "webmaster@ouba.eu",
          }
          await mailServices.sendMailToResidents(mailInfo)
        }
        openNotification(`L'annonce ${newClassifiedInfo?.publication?.title} a été supprimé`, 'success')
      }
    }
    setEditModalOpened(!editModalOpened);
    refreshData();
  };

  const updateCategoriesFilter = (value) => {
    setCategoriesFilter(value)
    refreshData()
  }

  const updateDirectionFilter = (value) => {
    setDirectionFilter(value)
    refreshData()
  }

  return {
    categories,
    categoriesFilter,
    classifiedCount,
    classifiedList,
    direction,
    directionFilter,
    editModalOpened,
    newClassifiedInfo,
    notifColor,
    notifMessage,
    myself,
    openNotif,
    limitClassified,
    page,
    handleFormChange,
    handlePagination,
    refreshData,
    setOpenNotif,
    submitNewClassified,
    toggleModal,
    updateCategoriesFilter,
    updateDirectionFilter,
  }
}

export default ClassifiedLogic