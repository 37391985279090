import { get_axs, post_axs, put_axs, remove_axs } from "helpers/fetcher";

export const collectionServices = {
  getAll,
  add,
  destroy,
  update,
};

async function getAll() {
  return get_axs("/collections");
}


async function add(title) {
  return post_axs(
    "/collections",
    new URLSearchParams({
      title
    })
  );
}

async function update(uuid, title) {
  return put_axs(
    `/collections/${uuid}`,
    new URLSearchParams({
      title
    })
  );
}

async function destroy(uuid) {
  return remove_axs(`/collections/${uuid}`);
}
