import { userConstants } from "../constants";
import { userService } from "services";
import { logout } from "services/common/user.services";
import { saveState } from "helpers/localStorage";

import { alertActions } from "./";
import { history } from "helpers";
import { siteActions } from "./site.actions";
import { modalActions } from "./modal.actions";

export const userActions = {
  login,
  refreshLogout,
  getAllBySite,
  getUser,
  forgotPassword,
  getSelf,
  sendNewPassword,
  create,
  update,
  toggleEnable,
  controlAccess,
};

function getAllBySite(page, search, state, sectorsFilter, groupsFilter) {
  return dispatch => {
    dispatch(request());
    userService
      .getAllBySite(page, search, state, sectorsFilter, groupsFilter)
      .then(
        users => dispatch(success(users)),
        error => dispatch(failure(error)),
      );
  };

  function request() {
    return { type: userConstants.GET_ALL_REQUEST };
  }

  function success(users) {
    return { type: userConstants.GET_ALL_SUCCESS, users };
  }

  function failure(error) {
    return { type: userConstants.GET_ALL_FAILURE, error };
  }
}

function getUser(uid) {
  return dispatch => {
    dispatch(request(uid));
    userService.getUser(uid).then(
      user => {
        dispatch(success(JSON.parse(user)));
        dispatch(modalActions.showUserModal());
      },
      error => {
        dispatch(failure(error));
        refreshLogout();
        history.push("/");
      },
    );
  };

  function request(uid) {
    return { type: userConstants.GETUSER_REQUEST, uid };
  }

  function success(user) {
    return { type: userConstants.GETUSER_SUCCESS, user };
  }

  function failure(error) {
    return { type: userConstants.GETUSER_FAILURE, error };
  }
}

function getSelf() {
  return dispatch => {
    dispatch(request());
    userService.getSelf().then(
      myself => {
        saveState("myself", myself);
        dispatch(success(myself));
      },
      error => {
        dispatch(failure(error));
        refreshLogout();
        history.push("/");
      },
    );
  };

  function request() {
    return { type: userConstants.GET_SELF_REQUEST };
  }

  function success(myself) {
    return { type: userConstants.GET_SELF_SUCCESS, myself };
  }

  function failure(error) {
    return { type: userConstants.GET_SELF_FAILURE, error };
  }
}

function forgotPassword(email) {
  return dispatch => {
    dispatch(request());
    userService.forgotPassword(email).then(
      response => dispatch(success(response)),
    ).catch(error => {
      dispatch(failure((error)));
    });
  };

  function request() {
    return { type: userConstants.FORGOT_PASSWORD_REQUEST };
  }

  function success(response) {
    return { type: userConstants.FORGOT_PASSWORD_SUCCESS, response };
  }

  function failure(error) {
    return { type: userConstants.FORGOT_PASSWORD_FAILURE, error };
  }
}

function sendNewPassword(uid, token) {
  return dispatch => {
    dispatch(request());
    userService.sendNewPassword(uid, token).then(
      response => dispatch(success(response)),
    ).catch(error => dispatch(failure(error)));
  };

  function request() {
    return { type: userConstants.RESET_PASSWORD_REQUEST };
  }

  function success(response) {
    return { type: userConstants.RESET_PASSWORD_SUCCESS, response };
  }

  function failure(error) {
    return { type: userConstants.RESET_PASSWORD_FAILURE, error };
  }
}

function login(email, password) {
  return dispatch => {
    dispatch(request({ email }));
    userService.login(email, password).then(
      user => {
        if (user) {
          dispatch(success(user));
          dispatch(siteActions.getAll());
        }
      },
    ).catch(error => {
      dispatch(failure(error));
    });
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }

  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }

  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function refreshLogout() {
  return dispatch => {
    dispatch(request());
    logout();
    // window.location.reload();
  };

  function request() {
    return { type: userConstants.LOGOUT };
  }
}

function toggleEnable(user, token) {
  return dispatch => {
    dispatch(request(user));
    userService.toggleEnable(user, token).then(
      response => {
        dispatch(success(response));
        window.location.reload();
      },
    ).catch(error => {
      dispatch(failure(error));
    });
  };

  function request(user) {
    return { type: userConstants.TOGGLE_ENABLE_REQUEST, user };
  }

  function success(response) {
    return { type: userConstants.TOGGLE_ENABLE_SUCCESS, response };
  }

  function failure(error) {
    return { type: userConstants.TOGGLE_ENABLE_FAILURE, error };
  }
}

function create(userToCreate) {
  return dispatch => {
    dispatch(request(userToCreate));
    userService.createUser(userToCreate).then(
      response => dispatch(success(response)),
    ).catch(error => {
      dispatch(failure(error));
    });
  };

  function request(user) {
    return { type: userConstants.USERS_CREATE_REQUEST, user };
  }

  function success(response) {
    return { type: userConstants.USERS_CREATE_SUCCESS, response };
  }

  function failure(error) {
    return { type: userConstants.USERS_CREATE_FAILURE, error };
  }
}

function update(userToUpdate) {
  return dispatch => {
    dispatch(request(userToUpdate));
    userService.update(userToUpdate).then(
      response => dispatch(success(response)),
    ).catch(error => {
      dispatch(failure(error));
    });
  };

  function request(user) {
    return { type: userConstants.UPDATE_REQUEST, user };
  }

  function success(response) {
    return { type: userConstants.UPDATE_SUCCESS, response };
  }

  function failure(error) {
    return { type: userConstants.UPDATE_FAILURE, error };
  }
}

function controlAccess(userToValidate, decision, reason) {
  return async dispatch => {
    dispatch(request(userToValidate));
    await userService
      .controlAccess(userToValidate, decision, reason)
      .then(
        response => dispatch(success(response)),
      ).catch(
        error => dispatch(failure(error)),
      );
  };

  function request(user) {
    return { type: userConstants.CONTROL_ACCESS_REQUEST, user };
  }

  function success(response) {
    return { type: userConstants.CONTROL_ACCESS_SUCCESS, response };
  }

  function failure(error) {
    return { type: userConstants.CONTROL_ACCESS_FAILURE, error };
  }
}
