import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import moment from "moment";
import localization from "moment/locale/fr";

import { eventServices, moduleServices, featureServices } from "services";

moment.updateLocale("fr", localization);

const EventsLogic = () => {
  const { myself } = useSelector(userState => userState.myself);

  const limitEvents = 5;

  const [eventsList, setEventsList] = useState(undefined);
  const [eventsCount, setEventsCount] = useState(undefined);
  const [eventsRecurrence, setEventsRecurrence] = useState([]);

  const [page, setPage] = useState(1);
  const [editModalOpened, setEditModalOpened] = useState(false);
  const [newEventInfo, setNewEventInfo] = useState(undefined);
  const [modalType, setModalType] = useState(false);
  const [formModulesList, setFormModulesList] = useState(undefined)
  const [formValid, setFormValid] = useState(false);

  const [openNotif, setOpenNotif] = useState(false);
  const [notifMessage, setNotifMessage] = useState("");
  const [notifColor, setNotifColor] = useState("info");

  const [eventDestroy, setEventDestroy] = useState(undefined);
  const [refreshImage, setRefreshImage] = useState(undefined);

  const toggleConfirmEventDestroy = event => setEventDestroy(event);

  useEffect(() => {
    (async () => {
      await refresh();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const refresh = async () => {
    const events = await eventServices.getAll(page, limitEvents);
    if (events) {
      setEventsList(events?.rows);
      setEventsCount(events?.count);
      if (!refreshImage && events?.rows) {
        let r = {}
        events.rows.forEach(it => r[it.uuid] = false)
        setRefreshImage(r)
      }
      const recs = events.rows.map(event => {
        let recurrence = event.days_recurrence;
        if (!event.days_recurrence) recurrence = "0000000";
        const days = ["lun", "mar", "mer", "jeu", "ven", "sam", "dim"];
        const rec = [];
        for (let i in recurrence) {
          if (recurrence[i] === "1") rec.push({ d: days[i], active: true });
          else rec.push({ d: days[i], active: false });
        }
        return rec;
      });
      setEventsRecurrence(recs);
    }
  };

  const handlePagination = async pageNumber => {
    setPage(pageNumber);
  };

  const openNotification = (msg, color = "info", time = 6) => {
    setOpenNotif(false);
    setNotifMessage(msg);
    setNotifColor(color);
    setTimeout(() => setOpenNotif(false), time * 1000);
    setTimeout(() => setOpenNotif(true), 100);
  };

  const refreshImg = uuid => {
    const refreshOn = { ...refreshImage }
    refreshOn[uuid] = true
    setRefreshImage(refreshOn);
    setTimeout(() => {
      const refreshOff = { ...refreshImage }
      refreshOff[uuid] = false
      setRefreshImage(refreshOff)
    }, 700);
  };

  const getAllRessourceform = async () => {
    const module = await moduleServices.getAll();
    const feature = await featureServices.getAll()
    if (module && feature) {
      const event = module.find(it => it.identifier === "calendar")?.id
      if (event)
        setFormModulesList(feature.filter(it => it.module_id === event))
    }
  }

  const toggleModal = (event = null) => {
    getAllRessourceform()
    setFormValid(false);
    setModalType(event ? true : false);
    const eventInfo = {
      uuid: event ? event.uuid : null,
      title: event ? event.title : "",
      date_begin:
        event && event.date_begin
          ? moment(event.date_begin).format("YYYY-MM-DD")
          : "",
      date_end:
        event && event.date_end
          ? moment(event.date_end).format("YYYY-MM-DD")
          : "",
      time_begin: event && event.time_begin ? event.time_begin : "",
      time_end: event && event.time_end ? event.time_end : "",
      is_all_day_long: event ? event.is_all_day_long : false,
      days_recurrence:
        event && event.days_recurrence ? event.days_recurrence : "0000000",
      description: event ? event.description : "",
      features: event?.features ? event?.features.map(it => it.uuid) : [],
      image: null,
      has_picture: event?.has_picture ? event?.has_picture : false,
    };
    setNewEventInfo(eventInfo);
    setEditModalOpened(!editModalOpened);
  };

  const isFormValid = eventInfo => {
    if (
      !eventInfo?.title?.length ||
      !eventInfo?.date_begin?.length ||
      !eventInfo?.date_end?.length ||
      !eventInfo?.description?.length ||
      (!eventInfo?.is_all_day_long && !eventInfo?.time_begin?.length) ||
      (!eventInfo?.is_all_day_long && !eventInfo?.time_end?.length)
    ) {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  };

  const handleFormChange = (key, value, idx = 0) => {
    let eventInfo = { ...newEventInfo };
    if (key === "recurrence") {
      eventInfo.days_recurrence =
        eventInfo.days_recurrence.substring(0, idx) +
        (value ? "1" : "0") +
        eventInfo.days_recurrence.substring(idx + 1);
    } else {
      eventInfo[key] = value;
    }
    setNewEventInfo(eventInfo);
    isFormValid(eventInfo);
  };

  const submitNewEvent = async event => {
    event.preventDefault();
    setFormValid(false);
    const eventInfo = {
      ...newEventInfo,
      time_begin: newEventInfo.is_all_day_long ? null : newEventInfo.time_begin,
      time_end: newEventInfo.is_all_day_long ? null : newEventInfo.time_end,
      is_all_day_long: newEventInfo.is_all_day_long ? "1" : "0",
    };
    if (newEventInfo.uuid) {
      const features = eventsList.find(it => it.uuid === newEventInfo.uuid).features
      features.filter(feature => !newEventInfo.features.find(it => it === feature.uuid)).forEach(async it => await eventServices.detachFeature(newEventInfo.uuid, it.uuid))
      newEventInfo.features.filter(it => !features.find(feature => feature.uuid === it)).forEach(async it => await eventServices.attachFeature(newEventInfo.uuid, it))
      if (newEventInfo.image) {
        let data = new FormData();
        data.append("file", newEventInfo.image);
        await eventServices.setImage(newEventInfo.uuid, data);
        refreshImg(newEventInfo.uuid)
      }
      if (await eventServices.update(newEventInfo.uuid, eventInfo)) {
        openNotification(`L'évènement ${eventsList.find(f => f.uuid === newEventInfo.uuid)?.title} a été modifié`, 'success')
      } else {
        openNotification(`Erreur, une erreur est survenu lors de la modification de l'évènement ${eventsList.find(f => f.uuid === newEventInfo.uuid)?.title}`, 'danger')
      }
    } else {
      const event = await eventServices.add(eventInfo);
      if (event) {
        for (let x of newEventInfo.features) {
          await eventServices.attachFeature(event.uuid, x)
        }
        if (newEventInfo.image) {
          let data = new FormData();
          data.append("file", newEventInfo.image);
          await eventServices.setImage(event.uuid, data);
          refreshImg(event.uuid)
        }
        openNotification(`L'évènement ${event?.title} a été ajouté`, 'success')
      } else {
        openNotification(`Erreur, une erreur est survenu lors de l'ajout de l'évènement ${newEventInfo.title}`, 'danger')
      }
    }
    setEditModalOpened(!editModalOpened);
    setFormValid(true);
    refresh();
  };

  const deleteEvent = async uuid => {
    await eventServices.destroy(uuid)
    openNotification(`L'évènement ${eventsList.find(f => f.uuid === uuid)?.title} a été supprimé`, 'success')
    toggleConfirmEventDestroy(null)
    await refresh();
  };

  const refreshData = () => refresh();

  return {
    editModalOpened,
    eventDestroy,
    eventsCount,
    eventsList,
    eventsRecurrence,
    formModulesList,
    formValid,
    limitEvents,
    modalType,
    myself,
    notifColor,
    notifMessage,
    newEventInfo,
    openNotif,
    page,
    refreshImage,
    deleteEvent,
    handlePagination,
    handleFormChange,
    refreshData,
    setOpenNotif,
    submitNewEvent,
    toggleConfirmEventDestroy,
    toggleModal,
  };
};

export default EventsLogic;
