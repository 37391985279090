import { post_axs, remove_axs } from "helpers/fetcher";

export const incidentReportServices = {
  addOnDeclarationUUID,
  removeOnUUID,
};

async function addOnDeclarationUUID(declarationUUID, description) {
  return post_axs(
    `/modules/incidents/reports`,
    new URLSearchParams({
      declaration_uuid: declarationUUID,
      description: description,
    })
  );
}

async function removeOnUUID(reportUUID) {
  return remove_axs(`/modules/incidents/reports/${reportUUID}`);
}
