import { get_axs, post_axs, put_axs, remove_axs } from "helpers/fetcher";

export const newsPostServices = {
  getAll,
  getPost,
  add,
  update,
  destroy,
  attachCategory,
  detachCategory,
  attachFeature,
  detachFeature,
  attachFile,
  detachFile,
  getThumbImage,
};

async function getAll(page = 10, limit= 1) {
  return get_axs(`/modules/news/posts?page=${page}&limit=${limit}`);
}
async function getPost(uuid) {
  return get_axs(`/modules/news/posts/${uuid}`);
}

async function add(title, content, category, published) {
  return post_axs(
    `/modules/news/posts`,
    new URLSearchParams({
      category,
      content,
      title,
      published: published ? 1 : 0,
    })
  );
}

async function update(uuid, title, content, published, category) {
  return put_axs(
    `/modules/news/posts/${uuid}`,
    new URLSearchParams({
      category,
      content,
      title,
      published: published ? 1 : 0,
    })
  );
}

async function destroy(uuid) {
  return remove_axs(`/modules/news/posts/${uuid}`);
}

async function attachCategory(postUUID, categoryUUID) {
  return post_axs(`/modules/news/posts/${postUUID}/link/category/${categoryUUID}`);
}

async function detachCategory(postUUID) {
  return remove_axs(`/modules/news/posts/${postUUID}/link/category`);
}

async function attachFeature(postUUID, featureUUID) {
  return post_axs(`/modules/news/posts/${postUUID}/link/feature/${featureUUID}`);
}

async function attachFile(postUUID, fileUUID) {
  return post_axs(`/modules/news/posts/${postUUID}/file/${fileUUID}`);
}

async function detachFile(postUUID, fileUUID) {
  return remove_axs(`/modules/news/posts/${postUUID}/file/${fileUUID}`);
}

async function detachFeature(postUUID, featureUUID) {
  return remove_axs(`/modules/news/posts/${postUUID}/link/feature/${featureUUID}`);
}

async function getThumbImage(postUUID) {
  return get_axs(`/modules/news/posts/${postUUID}/thumb`);
}
