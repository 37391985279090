import React, { useEffect, useMemo } from "react";
import PlatformLogic from "./PlatformLogic";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Backdrop from "@material-ui/core/Backdrop";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
// Other front packages
import InputColor from "react-input-color";

// core components
import ConfirmModal from "components/ConfirmModal";
import GridItem from "components/Grid/GridItem.js";
import Skeleton from "@material-ui/lab/Skeleton";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomSelect from "components/CustomSelect/CustomSelect.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Uploadimage from "components/UploadImage/UploadImage.js";
import WarningIcon from '@material-ui/icons/Warning';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import styles from "assets/jss/material-dashboard-react/views/newsStyle.js";
import GetAppIcon from '@material-ui/icons/GetApp';
import { IconButton } from "@material-ui/core";

const textStyles = makeStyles({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  divider: {
    background: 'rgba(0,0,0,1)',
  }
});

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fbfbfb",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const useStyles = makeStyles(styles);

export default function Platform(props) {
  const classes = useStyles();
  const textClasses = textStyles();

  const {
    uuid,
    address,
    backgroundColor,
    city,
    country,
    creditWalletEnable,
    currency,
    estateCode,
    file,
    fileSelect,
    filesList,
    isDragActive,
    isDragAccept,
    isDragReject,
    mainColor,
    maintenanceMode,
    notifColor,
    notifMessage,
    getRefresh,
    openNotif,
    pictureDestroy,
    site,
    timezone,
    title,
    webHostName,
    zipcode,
    deleteImg,
    downloadFile,
    handleFileSelect,
    getDelete,
    getRootProps,
    getInputProps,
    onChangeImg,
    refreshData,
    setAddress,
    setbackgroundColor,
    setCity,
    setCountry,
    setCreditWalletEnable,
    setCurrency,
    setDeleteBackground,
    setDeleteIcon,
    setDeleteLogo,
    setEstateCode,
    setImgBackground,
    setImgIcon,
    setImgLogo,
    setMainColor,
    setMaintenanceMode,
    setOpenNotif,
    setTimezone,
    setTitle,
    setWebHostName,
    setZipcode,
    submitNewFile,
    toggleConfirmPictureDestroy,
    updateSite,
    uploadImg,
  } = PlatformLogic();

  useEffect(() => {
    refreshData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  if (site) {
    
    return (
      <div>
        <Snackbar
          place='tr'
          message={notifMessage}
          open={openNotif}
          close
          icon={notifColor === 'success' ? CheckCircleIcon : (notifColor === 'danger' ? WarningIcon : NotificationsIcon)}
          closeNotification={() => setOpenNotif(false)}
          color={notifColor}
        />
        {pictureDestroy && (
          <Modal
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
            className={classes.modal}
            onClose={() => toggleConfirmPictureDestroy(null)}
            open={pictureDestroy ? true : false}
          >
            <div className={classes.paper}>
              <ConfirmModal
                closeModal={() => toggleConfirmPictureDestroy(null)}
                kind={`Supprimer l'image ?`}
                makeAction={() => deleteImg(pictureDestroy)}
              />
            </div>
          </Modal>
        )}
        {site && (
          <div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="primary">
                    <h4 className={textClasses.cardTitleWhite}>
                      Paramètre du Site
                    </h4>
                    <p className={textClasses.cardCategoryWhite}>
                      {uuid}
                    </p>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={2}>
                        <InputLabel>Maintenance</InputLabel>
                        <Select
                          value={maintenanceMode || "null"}
                          onChange={event =>
                            setMaintenanceMode(event.target.value)
                          }
                          inputProps={{
                            name: "maintenance",
                            id: "maintenance",
                          }}
                        >
                          <MenuItem value={"null"}>
                            <em>Aucune</em>
                          </MenuItem>
                          <MenuItem value={"TECHNICAL"}>Technique</MenuItem>
                          <MenuItem value={"MIGRATION"}>Migration</MenuItem>
                        </Select>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={2}>
                        <FormControlLabel
                          value="credit_wallet_enable"
                          control={
                            <Switch
                              checked={creditWalletEnable}
                              onChange={event =>
                                setCreditWalletEnable(event.target.checked)
                              }
                              color="primary"
                              value={creditWalletEnable}
                            />
                          }
                          label="Recharge crédit"
                          labelPlacement="top"
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={2}>
                        <FormControlLabel
                          value="top"
                          control={
                            <InputColor
                              initialValue={mainColor}
                              onChange={color => setMainColor(color.hex)}
                              placement="right"
                            />
                          }
                          label="Couleur principale"
                          labelPlacement="top"
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={2}>
                        <FormControlLabel
                          value="top"
                          control={
                            <InputColor
                              initialValue={backgroundColor}
                              onChange={color => setbackgroundColor(color.hex)}
                              placement="right"
                            />
                          }
                          label="Couleur de fond"
                          labelPlacement="top"
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={3}>
                        <CustomInput
                          labelText="Titre"
                          id="titre"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: title,
                            onChange: event => setTitle(event.target.value),
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3}>
                        <CustomInput
                          labelText="Nom de domaine"
                          id="domain-name"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: webHostName,
                            onChange: event =>
                              setWebHostName(event.target.value),
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3}>
                        <CustomInput
                          labelText="Code résidence"
                          id="code"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: estateCode,
                            onChange: event =>
                              setEstateCode(event.target.value),
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={1}>
                        <CustomInput
                          labelText="Devise"
                          id="currency"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: currency,
                            onChange: event => setCurrency(event.target.value),
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Adresse"
                          id="address"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: address,
                            onChange: event => setAddress(event.target.value),
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={2}>
                        <CustomInput
                          labelText="Ville"
                          id="city"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: city,
                            onChange: event => setCity(event.target.value),
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={2}>
                        <CustomInput
                          labelText="Code Postale"
                          id="zipcode"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: zipcode,
                            onChange: event => setZipcode(event.target.value),
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={2}>
                          <CustomSelect
                            labelText="Pays"
                            id="country"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              value: country,
                              onChange: event => setCountry(event.target.value),
                            }}
                          >
                            <MenuItem value={"DE"}>Allemagne</MenuItem>
                            <MenuItem value={"AT"}>Autriche</MenuItem>
                            <MenuItem value={"BE"}>Belgique</MenuItem>
                            <MenuItem value={"BG"}>Bulgarie</MenuItem>
                            <MenuItem value={"CY"}>Chypre</MenuItem>
                            <MenuItem value={"HR"}>Croatie</MenuItem>
                            <MenuItem value={"DK"}>Danemark</MenuItem>
                            <MenuItem value={"ES"}>Espagne</MenuItem>
                            <MenuItem value={"EE"}>Estonie</MenuItem>
                            <MenuItem value={"FI"}>Finland</MenuItem>
                            <MenuItem value={"FR"}>France</MenuItem>
                            <MenuItem value={"GR"}>Grèce</MenuItem>
                            <MenuItem value={"HU"}>Hongrie</MenuItem>
                            <MenuItem value={"IE"}>Ireland</MenuItem>
                            <MenuItem value={"IT"}>Italie</MenuItem>
                            <MenuItem value={"LV"}>Lettonie</MenuItem>
                            <MenuItem value={"LT"}>Lituanie</MenuItem>
                            <MenuItem value={"LU"}>Luxembourg</MenuItem>
                            <MenuItem value={"MT"}>Malte</MenuItem>
                            <MenuItem value={"NL"}>Pays-Bas</MenuItem>
                            <MenuItem value={"PL"}>Pologne</MenuItem>
                            <MenuItem value={"PT"}>Portugal</MenuItem>
                            <MenuItem value={"CZ"}>République tchèque</MenuItem>
                            <MenuItem value={"RO"}>Roumanie</MenuItem>
                            <MenuItem value={"GB"}>Royaume-Uni</MenuItem>
                            <MenuItem value={"SK"}>Slovaquie</MenuItem>
                            <MenuItem value={"SI"}>Slovénie</MenuItem>
                            <MenuItem value={"SE"}>Suède</MenuItem>
                          </CustomSelect>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={2}>
                          <CustomSelect
                            labelText="Fuseau horaire"
                            id="timezone"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              value: timezone,
                              onChange: event => setTimezone(event.target.value),
                            }}
                          >
                            <MenuItem value={"Europe/Amsterdam"}>Europe/Amsterdam</MenuItem>
                            <MenuItem value={"Europe/Andorra"}>Europe/Andorra</MenuItem>
                            <MenuItem value={"Europe/Astrakhan"}>Europe/Astrakhan</MenuItem>
                            <MenuItem value={"Europe/Athens"}>Europe/Athens</MenuItem>
                            <MenuItem value={"Europe/Belgrade"}>Europe/Belgrade</MenuItem>
                            <MenuItem value={"Europe/Berlin"}>Europe/Berlin</MenuItem>
                            <MenuItem value={"Europe/Bratislava"}>Europe/Bratislava</MenuItem>
                            <MenuItem value={"Europe/Brussels"}>Europe/Brussels</MenuItem>
                            <MenuItem value={"Europe/Budapest"}>Europe/Budapest</MenuItem>
                            <MenuItem value={"Europe/Copenhagen"}>Europe/Copenhagen</MenuItem>
                            <MenuItem value={"Europe/Chisinau"}>Europe/Chisinau</MenuItem>
                            <MenuItem value={"Europe/Dublin"}>Europe/Dublin</MenuItem>
                            <MenuItem value={"Europe/Gibraltar"}>Europe/Gibraltar</MenuItem>
                            <MenuItem value={"Europe/Guernsey"}>Europe/Guernsey</MenuItem>
                            <MenuItem value={"Europe/Helsinki"}>Europe/Helsinki</MenuItem>
                            <MenuItem value={"Europe/Isle_of_Man"}>Europe/Isle_of_Man</MenuItem>
                            <MenuItem value={"Europe/Istanbul"}>Europe/Istanbul</MenuItem>
                            <MenuItem value={"Europe/Jersey"}>Europe/Jersey</MenuItem>
                            <MenuItem value={"Europe/Kaliningrad"}>Europe/Kaliningrad</MenuItem>
                            <MenuItem value={"Europe/Kyiv"}>Europe/Kyiv</MenuItem>
                            <MenuItem value={"Europe/Kirov"}>Europe/Kirov</MenuItem>
                            <MenuItem value={"Europe/Lisbon"}>Europe/Lisbon</MenuItem>
                            <MenuItem value={"Europe/London"}>Europe/London</MenuItem>
                            <MenuItem value={"Europe/Ljubljana"}>Europe/Ljubljana</MenuItem>
                            <MenuItem value={"Europe/Luxembourg"}>Europe/Luxembourg</MenuItem>
                            <MenuItem value={"Europe/Madrid"}>Europe/Madrid</MenuItem>
                            <MenuItem value={"Europe/Malta"}>Europe/Malta</MenuItem>
                            <MenuItem value={"Europe/Monaco"}>Europe/Monaco</MenuItem>
                            <MenuItem value={"Europe/Mariehamn"}>Europe/Mariehamn</MenuItem>
                            <MenuItem value={"Europe/Minsk"}>Europe/Minsk</MenuItem>
                            <MenuItem value={"Europe/Moscow"}>Europe/Moscow</MenuItem>
                            <MenuItem value={"Europe/Oslo"}>Europe/Oslo</MenuItem>
                            <MenuItem value={"Europe/Paris"}>Europe/Paris</MenuItem>
                            <MenuItem value={"Europe/Podgorica"}>Europe/Podgorica</MenuItem>
                            <MenuItem value={"Europe/Prague"}>Europe/Prague</MenuItem>
                            <MenuItem value={"Europe/Rome"}>Europe/Rome</MenuItem>
                            <MenuItem value={"Europe/Riga"}>Europe/Riga</MenuItem>
                            <MenuItem value={"Europe/Simferopol"}>Europe/Simferopol</MenuItem>
                            <MenuItem value={"Europe/Samara"}>Europe/Samara</MenuItem>
                            <MenuItem value={"Europe/Saratov"}>Europe/Saratov</MenuItem>
                            <MenuItem value={"Europe/San_Marino"}>Europe/San_Marino</MenuItem>
                            <MenuItem value={"Europe/Sarajevo"}>Europe/Sarajevo</MenuItem>
                            <MenuItem value={"Europe/Skopje"}>Europe/Skopje</MenuItem>
                            <MenuItem value={"Europe/Stockholm"}>Europe/Stockholm</MenuItem>
                            <MenuItem value={"Europe/Sofia"}>Europe/Sofia</MenuItem>
                            <MenuItem value={"Europe/Tallinn"}>Europe/Tallinn</MenuItem>
                            <MenuItem value={"Europe/Tirane"}>Europe/Tirane</MenuItem>
                            <MenuItem value={"Europe/Ulyanovsk"}>Europe/Ulyanovsk</MenuItem>
                            <MenuItem value={"Europe/Vilnius"}>Europe/Vilnius</MenuItem>
                            <MenuItem value={"Europe/Volgograd"}>Europe/Volgograd</MenuItem>
                            <MenuItem value={"Europe/Vaduz"}>Europe/Vaduz</MenuItem>
                            <MenuItem value={"Europe/Vatican"}>Europe/Vatican</MenuItem>
                            <MenuItem value={"Europe/Vienna"}>Europe/Vienna</MenuItem>
                            <MenuItem value={"Europe/Warsaw"}>Europe/Warsaw</MenuItem>
                            <MenuItem value={"Europe/Zagreb"}>Europe/Zagreb</MenuItem>
                            <MenuItem value={"Europe/Zurich"}>Europe/Zurich</MenuItem>
                          </CustomSelect>
                      </GridItem>
                    </GridContainer>

                    <GridContainer></GridContainer>
                  </CardBody>
                  <CardFooter>
                    <Button onClick={updateSite} color="primary">
                      Valider
                    </Button>
                  </CardFooter>
                </Card>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardBody>
                    <GridContainer spacing={3}>
                      <GridItem xs={12} sm={12} md={12} style={{ marginBottom: '1.5%' }}>
                        <InputLabel>Règlement de copropriété</InputLabel>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12} style={{ marginBottom: '1.5%' }}>
                        {site?.file && (
                          <div style={{ fontSize: 18 }}>
                            {`Règlement: ${site?.file?.name}`}
                            <IconButton
                              aria-label="download"
                              onClick={downloadFile}
                            >
                              <GetAppIcon />
                            </IconButton>
                          </div>)
                        }
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <InputLabel>Déposez un fichier</InputLabel>
                        <div style={{ marginTop: "20px" }}>
                          <div {...getRootProps({ style })}>
                            <input {...getInputProps()} />
                            {isDragActive ? (
                              <p style={{ fontSize: 16 }}>
                                Déposer le fichier ici ...
                              </p>
                            ) : (
                              <p style={{ fontSize: 16 }}>
                                Déposer le fichier ici ou selectionner le en
                                cliquant ici
                              </p>
                            )}
                          </div>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={1}>
                        <InputLabel>Ou</InputLabel>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={5}>
                        <InputLabel>Sélectionez un fichier</InputLabel>
                        {filesList ? (
                          <Select
                            value={fileSelect || ""}
                            style={{ top: '35px' }}
                            id="select-type"
                            // fullWidth
                            displayEmpty
                            onChange={event =>
                              handleFileSelect(event.target.value)
                            }
                          >
                            <MenuItem value={""}>
                              <em>Aucune séléction</em>
                            </MenuItem>
                            {filesList &&
                              filesList.map((it, i) => (
                                <MenuItem key={i} value={it.uuid}>
                                  <div>{it.name}</div>
                                </MenuItem>
                              ))}
                          </Select>
                        ) : (
                          <Typography variant="h3">
                            <Skeleton width="auto" />
                          </Typography>
                        )}
                      </GridItem>
                      <div style={{ marginTop: '30px', display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                        {(file || fileSelect) && (<Button
                          onClick={submitNewFile}
                          color="primary">
                          Enregister le nouveau règlement{` (${file ? file.name : filesList.find(it => it.uuid === fileSelect)?.name})`}
                        </Button>)
                        }
                      </div>
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <Uploadimage
              setDelete={setDeleteIcon}
              uploadImg={uploadImg}
              deleteImg={toggleConfirmPictureDestroy}
              type="favicon"
              uuid={site.uuid}
              getDelete={getDelete}
              title="Icône du Site"
              onChangeImg={onChangeImg}
              hasImage={site.has_image_favicon}
              setImg={setImgIcon}
              getRefresh={getRefresh}
            />
            <Uploadimage
              setDelete={setDeleteLogo}
              uploadImg={uploadImg}
              deleteImg={toggleConfirmPictureDestroy}
              type="logo"
              uuid={site.uuid}
              getDelete={getDelete}
              title="Logo"
              onChangeImg={onChangeImg}
              hasImage={site.has_image_logo}
              setImg={setImgLogo}
              getRefresh={getRefresh}
            />
            <Uploadimage
              setDelete={setDeleteBackground}
              uploadImg={uploadImg}
              deleteImg={toggleConfirmPictureDestroy}
              type="background"
              uuid={site.uuid}
              getDelete={getDelete}
              title="Fond d'écran"
              onChangeImg={onChangeImg}
              hasImage={site.has_image_background}
              setImg={setImgBackground}
              getRefresh={getRefresh}
            />
          </div>
        )
        }
      </div >
    );
  } else {
    return (
      <div className={textClasses.circularProgress}>
        <CircularProgress />
      </div>
    );
  }
}
