import { get_axs, get_cdn, remove_axs } from "helpers/fetcher";

export const classifiedPublicationServices = {
  getAll,
  destroy,
  getPicture,
};

async function getAll() {
  return get_axs("/modules/classifieds/publications/");
}

async function destroy(uuid) {
  return remove_axs(`/modules/classifieds/publications/${uuid}`);
}

async function getPicture(uuid, type) {
  return get_cdn(`/app/module/classified/publications/${uuid}/${type}`);
}