import { useEffect, useState } from "react";

import { linkGroupServices } from "services";

const LinksSettingsLogic = () => {

  const limitGroups = 20

  const [refresh, setRefresh] = useState(false);

  const [openNotif, setOpenNotif] = useState(false);
  const [notifMessage, setNotifMessage] = useState("");
  const [notifColor, setNotifColor] = useState("info");

  const [page, setPage] = useState(1)
  const [groupsList, setGroupsList] = useState(undefined)
  const [groupsCount, setGroupsCount] = useState(undefined)

  const [editModalOpened, setEditModalOpened] = useState(false);
  const [newGroupsInfo, setNewGroupsInfo] = useState(undefined);
  const [modalType, setModalType] = useState(false);

  const [linkGroupDestroy, setLinkGroupDestroy] = useState(undefined);

  const toggleConfirmLinkGroupDestroy = link => setLinkGroupDestroy(link);

  useEffect(() => {
    (async () => {
      const group = await linkGroupServices.getAll()
      if (group) {
        setGroupsCount(group.length)
        setGroupsList(group.slice(limitGroups * (page - 1), limitGroups * (page - 1) + limitGroups))
      }
    })();
  }, [refresh, page]);

  const refreshData = () => setRefresh(!refresh);

  const handlePagination = page => {
    setPage(page);
  };

  const openNotification = (msg, color = "info", time = 6) => {
    setOpenNotif(false);
    setNotifMessage(msg);
    setNotifColor(color);
    setTimeout(() => setOpenNotif(false), time * 1000);
    setTimeout(() => setOpenNotif(true), 100);
  };

  const deleteGroup = async uuid => {
    await linkGroupServices.destroy(uuid)
    openNotification(`Le groupe ${groupsList.find(it => it.uuid === uuid)?.title} a été supprimé`, 'success')
    toggleConfirmLinkGroupDestroy(null)
    refreshData()
  };

  const toggleModal = async (group = null) => {
    setModalType(group ? true : false);
    const groupInfo = {
      uuid: group?.uuid,
      title: group?.title ? group?.title : '',
    };
    setNewGroupsInfo(groupInfo);
    setEditModalOpened(!editModalOpened);
  };

  const handleFormChange = (key, value) => {
    let groupInfo = { ...newGroupsInfo };
    groupInfo[key] = value;
    setNewGroupsInfo(groupInfo);
  };

  const submitNewGroup = async event => {
    event.preventDefault();
    if (newGroupsInfo.uuid) {
      if (await linkGroupServices.update(newGroupsInfo.uuid, newGroupsInfo.title)) {
        openNotification(`Le groupe ${newGroupsInfo?.title} a été modifié`, 'success')
      } else {
        openNotification(`Erreur, une erreur est survenu lors de la modification du groupe ${newGroupsInfo?.title}`, 'danger')
      }
    } else {
      if (await linkGroupServices.add(newGroupsInfo.title)) {
        openNotification(`Le groupe ${newGroupsInfo?.title} a été ajouté`, 'success')
      } else {
        openNotification(`Erreur, une erreur est survenu lors de l'ajout du groupe ${newGroupsInfo?.title}`, 'danger')
      }
    }
    setEditModalOpened(!editModalOpened);
    refreshData();
  };

  return {
    editModalOpened,
    groupsCount,
    groupsList,
    linkGroupDestroy,
    limitGroups,
    modalType,
    newGroupsInfo,
    notifColor,
    notifMessage,
    openNotif,
    page,
    deleteGroup,
    handleFormChange,
    handlePagination,
    refreshData,
    setOpenNotif,
    submitNewGroup,
    toggleConfirmLinkGroupDestroy,
    toggleModal,
  }
}

export default LinksSettingsLogic